<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card class="animated fadeInUp rounded-0">
          <v-card-title :class="`flex flex-row-reverse ` + theme.color + ` lighten-2`">
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="openForm"
                  >add_circle</v-icon>
                </v-btn>
              </template>
              <span>Tambah Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="fetchRecords"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-col :cols="device.desktop ? `4`: `9`">
              <v-text-field
                solo
                v-model="search"
                append-icon="mdi-magnify"
                label="Pencarian"
                single-line
                hide-details
                dense
                :color="theme.color"
              ></v-text-field>
            </v-col>
          </v-card-title>
          <v-data-table
            v-show="device.desktop"
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.status="{ value }">
              <v-icon
                small
                :color="value == true ? 'green' : 'red'"
                v-show="true"
              >calendar_today</v-icon>
            </template>
            <template v-slot:item.id="{ value }">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="theme.color"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-dots-vertical-circle-outline
                  </v-icon>
                </template>

                <v-list>
                  <v-list-item @click="">
                    <v-list-item-title>
                      <v-icon
                        color="purple"
                        class="mr-2"
                      >mdi-printer-search</v-icon>
                      Preview
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openButirSoal(value)">
                    <v-list-item-title>
                      <v-icon
                        color="green"
                        class="mr-2"
                      >article</v-icon>
                      Kelola Butir Soal
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="editRecord(value)">
                    <v-list-item-title>
                      <v-icon
                        color="orange"
                        class="mr-2"
                      >create</v-icon>
                      Edit Soal
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="postDeleteRecord(value)">
                    <v-list-item-title>
                      <v-icon
                        color="red"
                        class="mr-2"
                      >mdi-delete</v-icon>
                      Hapus Soal
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <v-list
            subheader
            v-show="device.mobile"
          >

            <v-list-item-group active-class="">
              <v-list-item
                v-for="item in filterItems"
                :key="item.id"
                style="border: ;1px solid"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.kegiatan }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.kelas }} {{ item.jurusan }} </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.mapel }} </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.guru}} </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :color="theme.color"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-vertical-circle-outline
                        </v-icon>
                      </template>

                      <v-list>

                        <v-divider v-if="page.delete || page.edit"></v-divider>
                        <v-list-item @click="">
                          <v-list-item-title>
                            <v-icon color="purple">mdi-printer-search</v-icon>
                            Preview
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openButirSoal(item.id)">
                          <v-list-item-title>
                            <v-icon color="green">article</v-icon>
                            Kelola Butir Soal
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="editRecord(item.id)">
                          <v-list-item-title>
                            <v-icon color="orange">create</v-icon>
                            Edit Soal
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="postDeleteRecord(item.id)">
                          <v-list-item-title>
                            <v-icon color="red">mdi-delete</v-icon>
                            Hapus Soal
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>

                </template>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog
        v-model="page.add"
        width="500"
        persistent
        :fullscreen="device.mobile"
      >
        <v-card>
          <v-card-title :class="`white--text ` + theme.color + ` lighten-2` ">
            Formulir Pembuatan Soal
          </v-card-title>

          <v-card-text>
            <v-col cols="12">
              <v-select
                label="Kegiatan"
                :color="theme.color"
                v-model="record.master_kegiatan_id"
                :items="kegiatans"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              v-show="false"
            >
              <v-select
                label="Prog. Keahlian"
                :color="theme.color"
                v-model="record.jurusan_id"
                :items="jurusans"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Prog. Keahlian"
                :color="theme.color"
                v-model="record.jurusans"
                :items="jurusans"
                outlined
                dense
                hide-details
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Kelas/Tingkat"
                :color="theme.color"
                v-model="record.master_tingkat_id"
                :items="tingkats"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Mata Pelajaran"
                :color="theme.color"
                v-model="record.master_mapel_id"
                :items="mapels"
                hide-details
                outlined
                dense
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="Judul Soal"
                :color="theme.color"
                v-model="record.title"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Keterangan"
                :color="theme.color"
                v-model="record.description"
                hide-details
                outlined
                dense
              >
                {{ record.description }}
              </v-textarea>
            </v-col>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              @click="closeForm"
            > Batal </v-btn>
            <v-btn
              color="blue dark-3"
              text
              @click="postAddNewRecord"
              v-show="!event.update"
            >TAMBAH</v-btn>
            <v-btn
              color="blue dark-3"
              text
              @click="postUpdateRecord"
              v-show="event.update"
            >UBAH</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "bank-soal",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", width: 50, align: "right", sortable: false },
      { text: "KEGIATAN", value: "kegiatan" },
      { text: "KLS", value: "kelas", width: 75 },
      { text: "PROG.AHLI", value: "jurusan" },
      { text: "MAPEL", value: "mapel" },
      { text: "JML", value: "jumlah" },
      { text: "GURU", value: "guru" },
      {
        text: "AKSI",
        value: "id",
        width: 85,
        align: "center",
        sortable: false,
      },
    ],

    search: null,
    form: {
      addnew: false,
      edit: false,
    },
    jurusans: [],
    tingkats: [],
    semesters: [],
    mapels: [],
    kegiatans: [],
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/master-soal",
      pagination: false,
      key: "uuid",
      title: "BANK SOAL",
      subtitle: "Berikut Daftar Seluruh Soal Yang Tersedia",
      breadcrumbs: [
        {
          text: "Bank Soal",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });
    this.fetchRecords();
    this.fetchJurusans();
    this.fetchTingkats();
    this.fetchMapels();
    this.fetchKegiatans();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "postAddNew",
      "postUpdate",
      "postEdit",
      "fetchRecords",
      "postConfirmDelete",
    ]),
    openForm: function () {
      this.setPage({
        add: true,
        edit: false,
      });
    },
    closeForm: function () {
      this.setPage({
        add: false,
        edit: false,
      });
    },

    postAddNewRecord: function () {
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.closeForm();
      });
    },

    editRecord: function (val) {
      this.postEdit(val);
      this.setPage({
        add: true,
        edit: true,
      });
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.closeForm();
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    openButirSoal: function (val) {
      this.$router.push({ name: "butir-soal", params: { id: val } });
    },

    fetchJurusans: async function () {
      let { data: data } = await this.http.post("api/jurusan-combo");
      this.jurusans = data;
    },

    fetchTingkats: async function () {
      let { data: data } = await this.http.post("api/master-tingkat-combo");

      this.tingkats = data;
    },

    fetchMapels: async function () {
      let { data: data } = await this.http.post("api/master-mapel-combo");

      this.mapels = data;
    },

    fecthSemeters: async function () {
      let { data: data } = await this.http.post("api/master-semester-combo");

      this.semesters = data;
    },

    fetchKegiatans: async function () {
      let { data: data } = await this.http.post(
        "api/master-kegiatan-combo",
        this.record
      );

      this.kegiatans = data;
    },
  },
  watch: {
    "record.master_semester_id": function () {
      this.fetchKegiatans();
    },
  },
};
</script>
