import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "../providers/AuthProviders";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import Home from "../views/backend/pages/Dashboard.vue";
import Base from "../views/backend/Base.vue";
import About from "../views/About.vue";

import User from "../views/backend/pages/User.vue";
import Changepwd from "../views/backend/pages/user/Changepwd.vue";
import ProfilAkun from "../views/backend/pages/user/Profile.vue";
import Sekolah from "../views/backend/pages/Sekolah.vue";

/**
 * Route Master
 */
import MasterPendidikan from "../views/backend/pages/masterdata/MasterPendidikan.vue";
import MasterJabatan from "../views/backend/pages/MasterJabatan.vue";
import MasterTingkat from "../views/backend/pages/MasterTingkat.vue";
import Jurusan from "../views/backend/pages/Jurusan.vue";
import MasterPeriode from "../views/backend/pages/MasterPeriode.vue";
import MasterSemester from "../views/backend/pages/MasterSemester.vue";
import MasterLibur from "../views/backend/pages/MasterLibur.vue";
import MasterRuang from "../views/backend/pages/MasterRuang.vue";
import MasterKelas from "../views/backend/pages/MasterKelas.vue";
import MasterGroupMapel from "../views/backend/pages/MasterGroupMapel.vue";
import MasterMapel from "../views/backend/pages/MasterMapel.vue";
import MasterPegawai from "../views/backend/pages/pegawai/Index.vue";
import MasterPegawaiCreate from "../views/backend/pages/pegawai/Create.vue";
import MasterPegawaiEdit from "../views/backend/pages/pegawai/Edit.vue";
import MasterSiswa from "../views/backend/pages/kurikulum/MasterSiswa.vue";
import WaliKelas from "../views/backend/pages/WaliKelas.vue";
import MasterKegiatan from "../views/backend/pages/MasterKegiatan.vue";
import MasterSoal from "../views/backend/pages/soal/Index.vue";
import MasterSoalHeader from "../views/backend/pages/soal/HeaderIndex.vue";
import CreateSoal from "../views/backend/pages/soal/CreateSoal.vue";
import EditSoal from "../views/backend/pages/soal/EditSoal.vue";
import JadwalUjian from "../views/backend/pages/ujian/JadwalUjian.vue";
import RombelUjian from "../views/backend/pages/ujian/RombelUjian.vue";
import MasterDokumen from "../views/backend/pages/masterdata/MasterDokumen.vue";

//kurikulum
import KurikulumPegawaiDocument from "../views/backend/pages/kurikulum/pegawai/document/Index.vue";
import MasterKelasSiswa from "../views/backend/pages/kurikulum/MasterKelasSiswa.vue";
import AbsenUjianKurikulum from "../views/backend/pages/kurikulum/AbsenUjian.vue";
import MutasiKelasSiswa from "../views/backend/pages/kurikulum/MutasiKelas/Index.vue";

//wali kelas
import WaliKelasAbsesiUjianSiswa from "../views/backend/pages/walikelas/AbsensiUjianSiswa.vue";
import WaliKelasAbsensiKelasUjianSiswa from "../views/backend/pages/walikelas/AbsensiKelasUjianSiswa.vue";
import WaliKelasRombel from "../views/backend/pages/walikelas/Rombel.vue";

//Menu Guru
import GuruDokumen from "../views/backend/pages/guru/dokumen/Index.vue";
import GuruNilaiUjianSiswa from "../views/backend/pages/guru/daftarnilai/Index.vue";
import GuruOlahNilai from "../views/backend/pages/guru/olahnilai/Index.vue";

//Areas Manajemen Siswa
import UserSiswa from "../views/backend/pages/siswa/UserSiswa.vue";

//Area Perpustakaan
import Blok from "../views/backend/pages/Blok.vue";
import Rak from "../views/backend/pages/Rak.vue";
import Kategori from "../views/backend/pages/Kategori.vue";
import Penerbit from "../views/backend/pages/Penerbit.vue";

//Area Utility
import UtilityInfoSekolah from "../views/backend/pages/utility/infosekolah/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    meta: {
      auth: true,
    },
    component: Base,
    children: [
      { path: "", redirect: { name: "dashboard" } },
      { path: "dashboard", name: "dashboard", component: Home },
      { path: "sekolah", name: "sekolah", component: Sekolah },
      { path: "about", name: "about", component: About },
      { path: "user", name: "user", component: User },
      {
        path: "chngpwd",
        name: "chngpwd",
        component: Changepwd,
      },
      {
        path: "profil-akun",
        name: "profil-akun",
        component: ProfilAkun,
      },

      //menus perpustakaan
      { path: "blok", name: "blok", component: Blok },
      { path: "rak", name: "rak", component: Rak },
      { path: "kategori", name: "kategori", component: Kategori },
      { path: "penerbit", name: "penerbit", component: Penerbit },

      //menu sims
      {
        path: "/master-pendidikan",
        name: "master-pendidikan",
        component: MasterPendidikan,
      },
      {
        path: "/master-jabatan",
        name: "master-jabatan",
        component: MasterJabatan,
      },
      {
        path: "/master-tingkat",
        name: "master-tingkat",
        component: MasterTingkat,
      },
      {
        path: "/jurusan",
        name: "jurusan",
        component: Jurusan,
      },
      {
        path: "/master-periode",
        name: "master-periode",
        component: MasterPeriode,
      },
      {
        path: "/master-semester",
        name: "master-semester",
        component: MasterSemester,
      },
      {
        path: "/master-libur",
        name: "master-libur",
        component: MasterLibur,
      },
      {
        path: "/master-ruang",
        name: "master-ruang",
        component: MasterRuang,
      },
      {
        path: "/master-kelas",
        name: "master-kelas",
        component: MasterKelas,
      },
      {
        path: "/master-group-mapel",
        name: "master-group-mapel",
        component: MasterGroupMapel,
      },
      {
        path: "/master-mapel",
        name: "master-mapel",
        component: MasterMapel,
      },
      {
        path: "/master-pegawai",
        name: "master-pegawai",
        component: MasterPegawai,
      },
      {
        path: "/master-pegawai-create",
        name: "master-pegawai-create",
        component: MasterPegawaiCreate,
      },
      {
        path: "/master-pegawai-edit/:id",
        name: "master-pegawai-edit",
        component: MasterPegawaiEdit,
      },
      {
        path: "/wali-kelas",
        name: "wali-kelas",
        component: WaliKelas,
      },
      {
        path: "/master-kegiatan",
        name: "master-kegiatan",
        component: MasterKegiatan,
      },
      {
        path: "/master-dokumen",
        name: "master-dokumen",
        component: MasterDokumen,
      },
      {
        path: "/data-siswa",
        name: "master-siswa",
        component: MasterSiswa,
      },
      {
        path: "/master-soal",
        name: "master-soal",
        component: MasterSoal,
      },
      {
        path: "/butir-soal/:id",
        name: "butir-soal",
        component: MasterSoalHeader,
      },
      {
        path: "/create-soal/:master_soal_id",
        name: "create-soal",
        component: CreateSoal,
      },
      {
        path: "/edit-soal/:master_soal_id/:id",
        name: "edit-soal",
        component: EditSoal,
      },
      {
        path: "/jadwal-ujian",
        name: "jadwal-ujian",
        component: JadwalUjian,
      },
      {
        path: "/rombel-ujian/:ujian_id",
        name: "rombel-ujian",
        component: RombelUjian,
      },
      {
        path: "/kurikulum-absen-ujian",
        name: "kurikulum-absen-ujian",
        component: AbsenUjianKurikulum,
      },

      {
        path: "/user-siswa",
        name: "user-siswa",
        component: UserSiswa,
      },
      {
        path: "/rombel",
        name: "rombel",
        component: MasterKelasSiswa,
      },
      {
        path: "wali-kelas-rombel",
        name: "wali-kelas-rombel",
        component: WaliKelasRombel,
      },
      {
        path: "wali-kelas-absensi-ujian-siswa",
        name: "wali-kelas-absensi-ujian-siswa",
        component: WaliKelasAbsesiUjianSiswa,
      },
      {
        path: "wali-kelas-absensi-kelas-ujian-siswa/:ujian_id",
        name: "wali-kelas-absensi-kelas-ujian-siswa",
        component: WaliKelasAbsensiKelasUjianSiswa,
      },
      {
        path: "/guru-daftar-nilai-ujian-siswa/:ujian_id?/:master_kelas_id?",
        name: "guru-daftar-nilai-ujian-siswa",
        component: GuruNilaiUjianSiswa,
      },
      {
        path:
          "/guru-olah-nilai/:ujian_id/:master_kelas_id/:master_siswa_id/:nis/:name",
        name: "guru-olah-nilai",
        component: GuruOlahNilai,
      },
      {
        path: "/guru-dokumen",
        name: "guru-dokumen",
        component: GuruDokumen,
      },
      {
        path: "/mutasi-kelas",
        name: "mutasi-kelas",
        component: MutasiKelasSiswa,
      },
      {
        path: "/infosekolah",
        name: "infosekolah",
        component: UtilityInfoSekolah,
      },

      //route kurikulum
      {
        path: "/kurikulum-pegawai-dokumen/:pegawai_id",
        name: "kurikulum-pegawai-dokumen",
        component: KurikulumPegawaiDocument,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history",
});

/**
 * router match
 */

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.auth);

  if (requiresAuth) {
    if (!Auth.check) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    if (to.name === "login" && Auth.check) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  }
});

export default router;
