<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">

    <v-row>
      <v-col col="12">
        <v-card class="animated fadeInUp rounded-0">
          <v-card-title :class="`flex  ` + theme.color + ` lighten-2 white--text`">
            Formulir Tambah Soal
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-row :no-gutters="device.mobile">

              <v-col cols="12">
                <h4>SOAL :</h4>
              </v-col>
              <v-col :cols="12">
                <v-select
                  label="Jenis Soal"
                  :color="theme.color"
                  outlined
                  dense
                  hide-details
                  v-model="record.type"
                  :items="soal.types"
                > </v-select>
              </v-col>

              <v-col cols="9">
                <template>
                  <tinymce
                    id="d1"
                    v-model="soal.soal"
                  ></tinymce>
                </template>
              </v-col>
              <v-col cols="3">
                <v-img
                  :class="[theme + ' lighten-2 with-backdrop']"
                  :aspect-ratio="4 / 4"
                  :src="soal.image"
                  :lazy-src="soal.image"
                >
                  <div
                    class="
                      d-flex
                      flex-column
                      align-center
                      justify-center
                      user-select-none
                    "
                    style="height: 100%"
                  >
                    <div style="
                        position: absolute;
                        right: 8px;
                        bottom: 8px;
                        height: 36px;
                        width: 36px;
                      ">
                      <v-btn
                        icon
                        dark
                        @click="uploadFotoSoal"
                      >
                        <v-icon>photo_camera</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-img>
                <center>Gambar Max: 2Mb</center>
              </v-col>
              <v-col
                cols="12"
                v-show="record.type==1"
              >
                <h4>PILIHAN :</h4>
              </v-col>
              <v-col
                cols="10"
                v-show="record.type==1"
              >
                <v-text-field
                  label=""
                  prefix="A. "
                  :hide-details="device.desktop"
                  v-model="pilihan.a"
                ></v-text-field>
              </v-col>
              <v-col
                cols="2"
                v-show="record.type==1"
              >
                <v-img
                  :class="[theme + ' lighten-2 with-backdrop']"
                  :aspect-ratio="4 / 1"
                  :src="pilihan.image_a"
                  :lazy-src="pilihan.image_a"
                >
                  <div
                    class="
                      d-flex
                      flex-column
                      align-center
                      justify-center
                      user-select-none
                    "
                    style="height: 100%"
                  >
                    <div style="
                        position: absolute;
                        right: 8px;
                        bottom: 8px;
                        height: 36px;
                        width: 36px;
                      ">
                      <v-btn
                        icon
                        dark
                        @click="uploadImageA"
                      >
                        <v-icon>photo_camera</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-img>
                <center>Gambar Max: 2Mb</center>
              </v-col>
              <v-col
                cols="10"
                v-show="record.type==1"
              >
                <v-text-field
                  label=""
                  prefix="B. "
                  :hide-details="device.desktop"
                  v-model="pilihan.b"
                ></v-text-field>
              </v-col>
              <v-col
                cols="2"
                v-show="record.type==1"
              >
                <v-img
                  :class="[theme + ' lighten-2 with-backdrop']"
                  :aspect-ratio="4 / 1"
                  :src="pilihan.image_b"
                  :lazy-src="pilihan.image_b"
                >
                  <div
                    class="
                      d-flex
                      flex-column
                      align-center
                      justify-center
                      user-select-none
                    "
                    style="height: 100%"
                  >
                    <div style="
                        position: absolute;
                        right: 8px;
                        bottom: 8px;
                        height: 36px;
                        width: 36px;
                      ">
                      <v-btn
                        icon
                        dark
                        @click="uploadImageB"
                      >
                        <v-icon>photo_camera</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-img>
                <center>Gambar Max: 2Mb</center>
              </v-col>
              <v-col
                cols="10"
                v-show="record.type==1"
              >
                <v-text-field
                  label=""
                  prefix="C. "
                  :hide-details="device.desktop"
                  v-model="pilihan.c"
                ></v-text-field>
              </v-col>
              <v-col
                cols="2"
                v-show="record.type==1"
              >
                <v-img
                  :class="[theme + ' lighten-2 with-backdrop']"
                  :aspect-ratio="4 / 1"
                  :src="pilihan.image_c"
                  :lazy-src="pilihan.image_c"
                >
                  <div
                    class="
                      d-flex
                      flex-column
                      align-center
                      justify-center
                      user-select-none
                    "
                    style="height: 100%"
                  >
                    <div style="
                        position: absolute;
                        right: 8px;
                        bottom: 8px;
                        height: 36px;
                        width: 36px;
                      ">
                      <v-btn
                        icon
                        dark
                        @click="uploadImageC"
                      >
                        <v-icon>photo_camera</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-img>
                <center>Gambar Max: 2Mb</center>
              </v-col>
              <v-col
                cols="10"
                v-show="record.type==1"
              >
                <v-text-field
                  label=""
                  prefix="D. "
                  :hide-details="device.desktop"
                  v-model="pilihan.d"
                ></v-text-field>
              </v-col>
              <v-col
                cols="2"
                v-show="record.type==1"
              >
                <v-img
                  :class="[theme + ' lighten-2 with-backdrop']"
                  :aspect-ratio="4 / 1"
                  :src="pilihan.image_d"
                  :lazy-src="pilihan.image_d"
                >
                  <div
                    class="
                      d-flex
                      flex-column
                      align-center
                      justify-center
                      user-select-none
                    "
                    style="height: 100%"
                  >
                    <div style="
                        position: absolute;
                        right: 8px;
                        bottom: 8px;
                        height: 36px;
                        width: 36px;
                      ">
                      <v-btn
                        icon
                        dark
                        @click="uploadImageD"
                      >
                        <v-icon>photo_camera</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-img>
                <center>Gambar Max: 2Mb</center>
              </v-col>
              <v-col
                cols="10"
                v-show="record.type==1"
              >
                <v-text-field
                  label=""
                  prefix="E. "
                  :hide-details="device.desktop"
                  v-model="pilihan.e"
                ></v-text-field>
              </v-col>
              <v-col
                cols="2"
                v-show="record.type==1"
              >
                <v-img
                  :class="[theme + ' lighten-2 with-backdrop']"
                  :aspect-ratio="4 / 1"
                  :src="pilihan.image_e"
                  :lazy-src="pilihan.image_e"
                >
                  <div
                    class="
                      d-flex
                      flex-column
                      align-center
                      justify-center
                      user-select-none
                    "
                    style="height: 100%"
                  >
                    <div style="
                        position: absolute;
                        right: 8px;
                        bottom: 8px;
                        height: 36px;
                        width: 36px;
                      ">
                      <v-btn
                        icon
                        dark
                        @click="uploadImageE"
                      >
                        <v-icon>photo_camera</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-img>
                <center>Gambar Max: 2Mb</center>
              </v-col>

              <v-col
                cols="2"
                v-show="record.type==1"
              >
                <label for="">Jawaban</label>
                <v-select
                  Label="Jawaban Benar"
                  :items="jawabans"
                  v-model="soal.jawaban"
                  :hide-details="device.desktop"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="flex flex-row-reverse">
            <v-btn
              color="blue dark-3"
              outlined
              @click="postAddNewRecord"
            >SIMPAN</v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="red dark-3"
              @click="$router.go(-1)"
            >
              Batal
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "HomeCreate",

  data: () => ({
    num: 1,

    search: null,
    soal: {
      types: [
        { text: "PILIHAN GANDA", value: 1 },
        { text: "ESAI", value: 2 },
      ],
      master_soal_id: null,
      nomor: null,
      soal: null,
      image: "/",
      jawaban: null,
    },
    pilihan: {
      a: null,
      b: null,
      c: null,
      d: null,
      e: null,
      image_a: "/",
      image_b: "/",
      image_c: "/",
      image_d: "/",
      image_e: "/",
    },
    jawabans: [
      { text: "A", value: "A" },
      { text: "B", value: "B" },
      { text: "C", value: "C" },
      { text: "D", value: "D" },
      { text: "E", value: "E" },
    ],
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  filterItems() {
    if (this.search != null) {
      return this.records.filter((item) => {
        return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    } else {
      return this.records;
    }
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/master-soal-header/" + this.$route.params.master_soal_id,
      pagination: false,
      key: "uuid",
      title: "KELOLA BUTIR SOAL",
      subtitle: "Formulir Input Soal baru",
      breadcrumbs: [
        {
          text: "Kelola Butir Soal",
          disabled: true,
          href: "",
        },
        {
          text: "Formulir Soal baru",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });
    this.soal.master_soal_id = this.$route.params.master_soal_id;
    this.fetchJabatans();
    this.fetchPendidikans();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
    ]),
    openForm: function () {
      this.setPage({
        add: true,
        edit: false,
      });
    },
    closeForm: function () {
      this.setPage({
        add: false,
        edit: false,
      });
    },
    postAddNewRecord: async function () {
      this.record.master_soal_id = this.soal.master_soal_id;
      this.record.soal = this.soal.soal;
      //this.record.image = this.soal.image;
      this.record.jawaban = this.soal.jawaban;
      this.record.a = this.pilihan.a;
      //this.record.image_a = this.pilihan.image_a;
      this.record.b = this.pilihan.b;
      //this.record.image_b = this.pilihan.image_b;
      this.record.c = this.pilihan.c;
      //this.record.image_c = this.pilihan.image_c;
      this.record.d = this.pilihan.d;
      //this.record.image_d = this.pilihan.image_d;
      this.record.e = this.pilihan.e;
      //this.record.image_e = this.pilihan.image_e;
      let {
        data: { status, message },
      } = await this.http.post(
        "api/master-soal-header/" + this.soal.master_soal_id,
        this.record
      );

      if (!status) {
        this.snackbar.color = "red";
        this.snackbar.text = message;
        this.snackbar.state = true;
        return;
      }

      this.snackbar.color = "green";
      this.snackbar.text = message;
      this.snackbar.state = true;

      this.$router.go(-1);
    },

    editRecord: function (val) {
      this.postEdit(val);
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.$router.push({ name: "buku" });
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    fetchJabatans: async function () {
      let { data: data } = await this.http.post("api/master-jabatan-combo");

      this.jabatans = data;
    },

    fetchPendidikans: async function () {
      let { data: data } = await this.http.post("api/master-pendidikan-combo");

      this.pendidikans = data;
    },

    uploadFotoSoal: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "soals",
        },
        callback: (response) => {
          setTimeout(() => {
            this.soal.image = response.path;
            this.record.image = response.name;
          }, 1000);
        },
      });
    },

    uploadImageA: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "soals",
        },
        callback: (response) => {
          setTimeout(() => {
            this.pilihan.image_a = response.path;
            this.record.image_a = response.name;
          }, 1000);
        },
      });
    },

    uploadImageB: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "soals",
        },
        callback: (response) => {
          setTimeout(() => {
            this.pilihan.image_b = response.path;
            this.record.image_b = response.name;
          }, 1000);
        },
      });
    },

    uploadImageC: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "soals",
        },
        callback: (response) => {
          setTimeout(() => {
            this.pilihan.image_c = response.path;
            this.record.image_c = response.name;
          }, 1000);
        },
      });
    },

    uploadImageD: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "soals",
        },
        callback: (response) => {
          setTimeout(() => {
            this.pilihan.image_d = response.path;
            this.record.image_d = response.name;
          }, 1000);
        },
      });
    },

    uploadImageE: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "soals",
        },
        callback: (response) => {
          setTimeout(() => {
            this.pilihan.image_e = response.path;
            this.record.image_e = response.name;
          }, 1000);
        },
      });
    },

    batal: function () {
      this.$router.push({ name: "master-soal-header" });
    },
  },
};
</script>
