<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col cols="12">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title :class="`flex flex-row-reverse `+  theme.color + ` lighten-2` ">
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                  v-show="page.actions.add"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="openForm"
                  >add_circle</v-icon>
                </v-btn>
              </template>
              <span>Tambah Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                  v-show="page.actions.refresh"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="fetchRecords"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              dense
              :color="theme.color"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-show="device.desktop"
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>

            <template v-slot:item.soal="{ value }">
              <p v-html="value"></p>
            </template>

            <template v-slot:item.status="{ value }">
              <v-icon
                small
                :color="value == true ? 'green' : 'red'"
                v-show="true"
              >calendar_today</v-icon>
            </template>
            <template v-slot:item.id="{ value }">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="theme.color"
                    v-bind="attrs"
                    v-on="on"
                  >mdi-dots-vertical-circle-outline</v-icon>
                </template>

                <v-list>
                  <v-list-item @click="editRecord(value)">
                    <v-list-item-title>
                      <v-icon
                        class="mr-2"
                        :color="theme.color"
                      >mdi-folder</v-icon>Dokumen
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="editRecord(value)">
                    <v-list-item-title>
                      <v-icon
                        class="mr-2"
                        color="orange"
                      >mdi-pencil-circle</v-icon>Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="postDeleteRecord(value)">
                    <v-list-item-title>
                      <v-icon
                        class="mr-2"
                        color="red"
                      >mdi-delete-circle</v-icon>Hapus
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <v-list
            subheader
            v-show="device.mobile"
          >

            <v-list-item-group active-class="">
              <v-list-item
                v-for="item in filterItems"
                :key="item.id"
                style="border: ;1px solid"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.nama }}</v-list-item-title>
                    <v-list-item-subtitle>NIS. {{ item.nis }} </v-list-item-subtitle>
                    <v-list-item-subtitle>NIK. {{ item.nik }} </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :color="theme.color"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-vertical-circle-outline
                        </v-icon>
                      </template>

                      <v-list>

                        <v-divider v-if="page.delete || page.edit"></v-divider>
                        <v-list-item
                          @click="editRecord(item.id)"
                          v-show="page.actions.edit"
                        >
                          <v-list-item-title>
                            <v-icon
                              class="mr-2"
                              color="orange"
                            >mdi-pencil-circle</v-icon>
                            Ubah Data
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="postDeleteRecord(item.id)"
                          v-show="page.actions.delete"
                        >
                          <v-list-item-title>
                            <v-icon
                              class="mr-2"
                              color="red"
                            >mdi-delete-circle</v-icon>
                            Hapus Data
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>

                </template>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "daftar-siswa",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", width: 50, align: "right", sortable: false },
      { text: "NIS", value: "nis", width: 150 },
      { text: "NIK", value: "nik", width: 150 },
      { text: "NAMA", value: "nama" },
      { text: "L/P", value: "jenis_kelamin", width: 75, align: "center" },
      { text: "TTL", value: "ttl" },
      {
        text: "AKSI",
        value: "id",
        width: 85,
        align: "center",
        sortable: false,
      },
    ],

    search: null,
    form: {
      addnew: false,
      edit: false,
    },
    master_soal_id: null,
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.nama.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/master-siswa",
      pagination: false,
      key: "uuid",
      title: "DAFTAR SISWA",
      subtitle: "Berikut Daftar Seluruh Daftar Siswa Yang Tersedia",
      breadcrumbs: [
        {
          text: "Daftar Siswa",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });

    this.fetchRecords(this.record);
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "postAddNew",
      "postUpdate",
      "postEdit",
      "fetchRecords",
      "postConfirmDelete",
    ]),
    openForm: function () {
      this.setPage({
        add: true,
        edit: false,
      });
    },
    closeForm: function () {
      this.setPage({
        add: false,
        edit: false,
      });
    },

    openAddNew: function () {
      alert("under construction");
    },
    closeAddNew: function () {
      this.event.update = false;
      this.form.addnew = false;
    },

    editRecord: function (val) {
      alert("under construction");
    },

    postDeleteRecord: function (val) {
      //this.postConfirmDelete(val);
    },
  },
};
</script>
