<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col :cols="12">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title :class="`flex flex-row-reverse white--text ` + theme.color + ` lighten-2`">
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                  v-show="page.actions.add"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="openForm"
                  >add_circle</v-icon>
                </v-btn>
              </template>
              <span>Tambah Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                  v-show="page.actions.refresh"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="fetchRecords"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              dense
              :color="theme.color"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.id="{ value }">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="theme.color"
                    v-bind="attrs"
                    v-on="on"
                  >mdi-dots-vertical-circle-outline</v-icon>
                </template>

                <v-list>
                  <v-list-item @click="editRecord(value)">
                    <v-list-item-title>
                      <v-icon
                        color="orange"
                        class="mr-2"
                      >mdi-pencil-circle</v-icon>Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="postDeleteRecord(value)">
                    <v-list-item-title>
                      <v-icon
                        color="red"
                        class="mr-2"
                      >mdi-delete-circle</v-icon>Hapus
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-col cols="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="page.add"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
        :fullscreen="device.mobile"
      >
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-toolbar
            :color="theme.color"
            :dark="theme.mode"
          >
            <v-icon class="mr-1">mdi-circle</v-icon> Formulir Manajemen Pengguna Apilkasi
          </v-toolbar>
          <v-card-text class="mt-5">
            <v-col cols="12">
              <v-text-field
                label="Nama Pengguna"
                :color="theme.color"
                hide-details="device.desktop"
                autocomplete="off"
                v-model="record.username"
                :disabled="event.update"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Email"
                :color="theme.color"
                type="email"
                hide-details="device.desktop"
                autocomplete="off"
                v-model="record.email"
                :disabled="event.update"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="5">
                  <v-switch
                    label="Status"
                    :color="theme.color"
                    v-model="record.status"
                    :hide-details="device.desktop"
                  ></v-switch>
                </v-col>
                <v-col cols="5">
                  <v-switch
                    label="Reset Kata Sandi"
                    :color="theme.color"
                    v-model="record.reset"
                    :hide-details="device.desktop"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              :color="theme.color"
              v-show=" !page.edit"
              @click="postAddNewRecord"
            >Tambah</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              v-show="page.edit"
              @click="postUpdateRecord"
            >Ubah</v-btn>
            <v-btn
              outlined
              color="grey"
              @click="closeForm"
            >Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "manajemen-pengguna-user",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", width: 50, align: "right", sortable: false },
      {
        text: "NAMA",
        align: "start",
        sortable: false,
        value: "username",
      },
      { text: "EMAIL", value: "email" },
      {
        text: "AKSI",
        value: "id",
        width: 85,
        align: "center",
        sortable: false,
      },
    ],
    status: [
      { text: "Aktif", value: 0 },
      { text: "Non Aktif", value: 1 },
    ],
    authents: [
      { text: "Administrator", value: "administrator" },
      { text: "Kepala Sekolah", value: "kepalasekolah" },
      { text: "Bendahara", value: "bendahara" },
      { text: "Tata Usaha", value: "tatausaha" },
      { text: "Wali Kelas", value: "walikelas" },
      { text: "Guru", value: "guru" },
      { text: "Panitia PPDB", value: "ppdb" },
      { text: "Siswa", value: "siswa" },
    ],
    search: null,
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/user-siswa",
      pagination: false,
      key: "uuid",
      title: "MANAJEMEN PENGGUNA SISWA",
      subtitle: "Berikut Daftar Seluruh Pengguna Siswa Yang Tersedia",
      breadcrumbs: [
        {
          text: "Manajemen Pengguna Siswa",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });
    this.fetchRecords();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
    ]),
    openForm: function () {
      this.setPage({
        add: true,
        edit: false,
      });
      //clear form
      this.record.id = null;
      this.record.username = null;
      this.record.email = null;
      this.record.authent = null;
      this.record.status = null;
    },
    closeForm: function () {
      this.setPage({
        add: false,
        edit: false,
      });
    },
    postAddNewRecord: function () {
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.closeForm();
      });
    },
    editRecord: function (val) {
      this.postEdit(val);
      this.setPage({
        add: true,
        edit: true,
      });
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.closeForm();
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },
  },
};
</script>
