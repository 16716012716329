<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :color="theme.color"
      dark
      app
      absolute
      hide-on-scroll
      prominent
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title"> S I M S </v-list-item-title>
          <v-list-item-subtitle> Ver. 4.04 </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-2">
        <v-list-item-avatar class="white">
          <v-img :src="user.avatar"></v-img>
        </v-list-item-avatar>
        <!--<v-list-item-title>{{ user.username }} </v-list-item-title>-->
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.username }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ user.authent }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <template v-for="(menu, index) in menus">
          <v-list-item
            :active-class="'puple' + '--text'"
            :to="menu.route"
            :key="index"
            v-if="menu.type === 'item'"
          >
            <v-list-item-action>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-subheader
            :class="menu.class"
            :key="index"
            class="overline mt-3"
            style="height: 24px"
            v-else-if="menu.type === 'subheader'"
          >{{ menu.title }}</v-subheader>
        </template>
      </v-list>

    </v-navigation-drawer>
    <div class="upload-wrapper">
      <div id="upload-button"></div>
    </div>
    <v-app-bar
      app
      dark
      color="white"
    >
      <template v-slot:img="{ props }">
        <v-img v-bind="props"></v-img>
      </template>

      <v-app-bar-nav-icon
        :color="theme.color"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="blue--text">{{ device.desktop ? 'SMK Negeri 4 Kab. Tangerang': 'SMKN 4 TNG' }} </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-menu
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">

          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            :class="device.desktop ? `mr-5`:`mr-2`"
          >
            <v-avatar
              size="32"
              class="grey"
            >
              <v-img :src="user.avatar"></v-img>
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list class="indigo lighten-2">
            <v-list-item>
              <v-list-item-avatar class="white">
                <v-img :src="user.avatar"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text">{{user.username}}</v-list-item-title>
                <v-list-item-subtitle class="white--text">{{user.authent}}</v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-list>
            <v-list-item @click="$router.push({name:'profil-akun'})">
              <v-list-item-action>
                <v-icon :color="theme.color">mdi-account-edit-outline</v-icon>
              </v-list-item-action>
              <v-list-item-title>Profil Akun</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push({name:'chngpwd'})">
              <v-list-item-action>
                <v-icon :color="theme.color">mdi-account-key</v-icon>
              </v-list-item-action>
              <v-list-item-title>Ganti Kata Sandi</v-list-item-title>
            </v-list-item>

          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="theme.color"
              text
              @click="signout"
            >
              <v-icon>mdi-logout</v-icon> Keluar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main class="main-content grey lighten-4">
      <v-card
        color="white"
        flat
        tile
        class="elevation-0"
        v-show="page.title ? true:false"
      >
        <v-toolbar
          flat
          class="elevation-1 "
          height="48px"
        >
          <div class="flex flex-column">
            <div class="toolbar-title">
              {{ page.title }}
            </div>
            <div class="toolbar-sub-title">
              {{ page.subtitle }}
            </div>

          </div>

          <v-spacer></v-spacer>

          <v-breadcrumbs
            v-show="device.desktop"
            :items="page.breadcrumbs"
          >
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </v-toolbar>
      </v-card>
      <router-view></router-view>
    </v-main>
    <v-snackbar
      v-model="snackbar.state"
      :color="snackbar.color"
      :timeout="3500"
      multi-line
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbarClose"
        >
          Tutup
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialog.delete"
      persistent
      max-width="300px"
    >
      <v-card>
        <v-card-title
          class="headline red white--text"
          light
        >
          <v-icon
            color="white"
            class="pr-3"
          >mdi-delete-circle</v-icon>
          <span>Konfirmasi</span>
        </v-card-title>
        <v-card-text>
          <p class="pt-5">Apakah anda yakin akan menghapus data tersebut...?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="theme.color"
            text
            @click="dialogDeleteClose"
          >Tidak</v-btn>
          <v-btn
            color="red"
            text
            @click="deleteRecord"
          >Ya, Saya Yakin</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-footer padless>
      <v-card
        flat
        tile
        width="100%"
        class=""
        :color="theme.color + ` lighten-5`"
      >
        <v-divider></v-divider>

        <v-card-text :class="theme.color +`--text   `">
          <v-row class="ml-5 mr-5">
            <span :class="drawer ? `footer-status`:`footer-status-mobile`">Status : {{ page.add ? (page.edit ?  "Ubah data":"Tambah data") :'Ready' }}</span>
            <v-spacer></v-spacer>
            Copyright &copy; {{ new Date().getFullYear() }} <span
              class="ml-2 mr-2"
              v-if="device.desktop"
            > - </span>
            <strong v-if="device.desktop">{{ "SMKN 4 KAB. TANGERANG"}}</strong>
          </v-row>
        </v-card-text>

      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import { baseMixins } from "../../mixings/BaseMixins";
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({
    drawer: null,
    vertical: true,
    bg: "",
  }),

  mixins: [baseMixins],

  computed: {
    ...mapState([
      "snackbar",
      "theme",
      "dialog",
      "user",
      "menus",
      "page",
      "device",
    ]),
  },
  created() {
    this.bg = this.page.domainUrl + "images/batik.png";
  },
  mounted() {
    this.getUserInfo();
    this.getMenus();
  },
  methods: {
    ...mapActions([
      "signOut",
      "snackbarClose",
      "postDelete",
      "dialogDeleteClose",
      "fetchRecords",
      "initUploadLibrary",
      "getUserInfo",
      "getMenus",
      "clearMenu",
    ]),

    signout: function () {
      this.clearMenu();
      this.signOut();
      this.$router.push("login");
    },
    deleteRecord: function () {
      this.postDelete().then(() => {
        this.fetchRecords();
      });
    },
  },
};
</script>

<style lang="sass">
@import "../../assets/css/wow.css"
</style>
<style>
.main-content {
  height: 82vh;
  overflow-y: scroll;
}
.toolbar-title {
  font-size: 15px;
  font-weight: 600;
  color: darkslategrey;
  font-stretch: condensed;
}
.people-title {
  font-family: people;
  font-size: 18px;
}
.toolbar-sub-title {
  font-size: 8pt;
  color: grey;
  font-weight: 500;
}
::before {
  border-radius: 0px;
}
.footer-status {
  margin-left: 250px;
  font-size: 9pt;
  color: grey;
}
.footer-status-mobile {
  margin-left: 0px;
  font-size: 9pt;
  color: grey;
}
</style>
