<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col cols="12">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title :class="`flex flex-row-reverse `+  theme.color + ` lighten-2`">
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                  v-show="page.actions.add"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="$router.push({name:'jadwal-ujian'})"
                  >mdi-close-circle</v-icon>
                </v-btn>
              </template>
              <span>Tutup</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                  v-show="page.actions.add"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="openForm"
                  >add_circle</v-icon>
                </v-btn>
              </template>
              <span>Tambah Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                  v-show="page.actions.refresh"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="fetchRecords"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              hide-details
              :color="theme.color"
              single-line
              solo
              outlined
              dense
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-show="device.desktop"
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.id="{ value }">
              <v-tooltip
                color="red"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="red"
                      @click="postDeleteRecord(value)"
                    >delete</v-icon>
                  </v-btn>
                </template>

                <span>Hapus Data</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-col cols="12">
      <v-dialog
        v-model="page.add"
        width="600"
        persistent
        :fullscreen="device.mobile"
      >
        <v-card>
          <v-card-title :class="theme.color + ` lighten-2 white--text`">
            Formulir Rombel
          </v-card-title>

          <v-card-text>
            <v-row class="mt-2">
              <v-col cols="12">
                <v-select
                  label="Prog.Keahlian"
                  :color="theme.color"
                  v-model="record.jurusan_id"
                  :hide-details="device.desktop"
                  :items="jurusans"
                  @change="fetchRombels"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Rombel"
                  :color="theme.color"
                  v-model="record.master_kelas_id"
                  :hide-details="device.desktop"
                  :items="rombels"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="flex flex-row-reverse ">

            <v-btn
              :color="theme.color"
              @click="postAddNewRecord"
              v-show="!page.edit"
              outlined
            >TAMBAH
            </v-btn>
            <v-btn
              :color="theme.color"
              @click="postUpdateRecord"
              outlined
              v-show="page.edit"
            >UBAH <v-icon>save</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="grey dark-3"
              @click="closeForm"
              outlined
            >BATAL</v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "pilih-rombel",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", width: 50, align: "center", sortable: false },
      {
        text: "PROG.KEAHLIAN",
        align: "start",
        sortable: false,
        value: "jurusan",
      },
      { text: "ROMBE/KELAS", value: "kelas" },
      {
        text: "AKSI",
        value: "id",
        width: 85,
        align: "center",
        sortable: false,
      },
    ],

    search: null,

    jurusans: [],

    rombels: [],
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/rombel-ujian/" + this.$route.params.ujian_id,
      pagination: false,
      key: "uuid",
      title: "JADWAL UJIAN ROMBEL",
      subtitle: "Berikut Daftar Seluruh Rombel Yang Tersedia",
      breadcrumbs: [
        {
          text: "Jadwal Ujian",
          disabled: true,
          href: "",
        },
        {
          text: "Rombel",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });
    this.fetchRecords();
    this.fetchJurusans();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
    ]),
    openForm: function () {
      this.setPage({
        add: true,
        edit: false,
      });
    },
    closeForm: function () {
      this.setPage({
        add: false,
        edit: false,
      });
    },
    postAddNewRecord: function () {
      this.record.ujian_id = this.$route.params.ujian_id;
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.closeForm();
      });
    },
    editRecord: function (val) {
      this.postEdit(val);
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    fetchJurusans: async function () {
      let { data: data } = await this.http.post("api/jurusan-combo");

      this.jurusans = data;
    },

    fetchRombels: async function () {
      let { data: data } = await this.http.post(
        "api/master-kelas-combo-by-jurusan",
        this.record
      );

      this.rombels = data;
    },
  },
  watch: {
    "record.jurusan_id": function () {
      this.fetchRombels();
    },
  },
};
</script>
