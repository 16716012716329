var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.device.desktop ? "home pa-6 grey lighten-4":"home pa-0 grey lighten-4"},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-card',{staticClass:"animated fadeInUp rounded-0"},[_c('v-card-title',{class:"flex flex-row-reverse " + _vm.theme.color + " lighten-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({name:'wali-kelas-absensi-ujian-siswa'})}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" highlight_off"),_c('span',{staticClass:"material-icons"})])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.postPrint}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-file-excel")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.fetchRecords}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("autorenew")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":_vm.device.desktop ?4:8}},[_c('v-text-field',{attrs:{"solo":"","append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","color":_vm.theme.color},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.desktop),expression:"device.desktop"}],staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme.color,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value == "SELESAI"
                  ? "green"
                  : value == "SEDANG MENGERJAKAN"
                  ? "yellow"
                  : "grey"}},[_vm._v(_vm._s(value))])]}},{key:"item.id",fn:function(ref){
                  var value = ref.value;
return [_c('v-tooltip',{attrs:{"color":"green","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){}}},[_vm._v("restart_alt"),_c('span',{staticClass:"material-icons"})])],1)]}}],null,true)},[_c('span',[_vm._v("Restart Ke Status Ujian | Masih Di Kembangkan")])])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme.color,"height":"1","indeterminate":""},slot:"progress"})],1),_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.mobile),expression:"device.mobile"}],attrs:{"subheader":""}},[_c('v-list-item-group',{attrs:{"active-class":""}},_vm._l((_vm.filterItems),function(item){return _c('v-list-item',{key:item.id,staticStyle:{"border":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v("Mulai : "+_vm._s(item.jam_mulai)+" ")]),_c('v-list-item-subtitle',[_vm._v("Selesai :"+_vm._s(item.jam_selesai)+" ")]),_c('v-list-item-subtitle',[_vm._v("Nilai : "+_vm._s(item.nilai))]),_c('v-list-item-subtitle',[_c('v-chip',{attrs:{"small":"","color":item.status.color}},[_vm._v(_vm._s(item.status.text))])],1)],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.theme.color}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical-circle-outline ")])]}}],null,true)},[_c('v-list',[(_vm.page.delete || _vm.page.edit)?_c('v-divider'):_vm._e(),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.page.actions.edit),expression:"page.actions.edit"}],on:{"click":function($event){return _vm.editRecord(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"orange"}},[_vm._v("mdi-pencil-circle")]),_vm._v(" Ubah Data ")],1)],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.page.actions.delete),expression:"page.actions.delete"}],on:{"click":function($event){return _vm.postDeleteRecord(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v("mdi-delete-circle")]),_vm._v(" Hapus Data ")],1)],1)],1)],1)],1)]}}],null,true)})}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }