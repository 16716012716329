<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title :class="`flex flex-row-reverse `+  theme.color + ` lighten-2`">
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                  v-show="page.actions.add"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="openForm"
                  >add_circle</v-icon>
                </v-btn>
              </template>
              <span>Tambah Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                  v-show="page.actions.refresh"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="fetchRecords"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              dense
              :color="theme.color"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-show="device.desktop"
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>

            <template v-slot:item.id="{ value }">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="theme.color"
                    v-bind="attrs"
                    v-on="on"
                  >mdi-dots-vertical-circle-outline</v-icon>
                </template>

                <v-list>
                  <v-list-item @click="openRombel(value)">
                    <v-list-item-title>
                      <v-icon
                        :color="theme.color"
                        class="mr-2"
                      >mdi-home</v-icon>Kelola Rombel
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="editRecord(value)">
                    <v-list-item-title>
                      <v-icon
                        color="orange"
                        class="mr-2"
                      >mdi-pencil-circle</v-icon>Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="postDeleteRecord(value)">
                    <v-list-item-title>
                      <v-icon
                        color="red"
                        class="mr-2"
                      >mdi-delete-circle</v-icon>Hapus
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <v-list
            subheader
            v-show="device.mobile"
          >

            <v-list-item-group active-class="">
              <v-list-item
                v-for="item in filterItems"
                :key="item.id"
                style="border: ;1px solid"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.ujian }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.jurusan }} </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.token }} </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.waktu}} </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :color="theme.color"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-vertical-circle-outline
                        </v-icon>
                      </template>

                      <v-list>

                        <v-divider v-if="page.delete || page.edit"></v-divider>
                        <v-list-item
                          @click="editRecord(item.id)"
                          v-show="page.actions.edit"
                        >
                          <v-list-item-title>
                            <v-icon
                              class="mr-2"
                              color="orange"
                            >mdi-pencil-circle</v-icon>
                            Ubah Data
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="postDeleteRecord(item.id)"
                          v-show="page.actions.delete"
                        >
                          <v-list-item-title>
                            <v-icon
                              class="mr-2"
                              color="red"
                            >mdi-delete-circle</v-icon>
                            Hapus Data
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>

                </template>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog
        v-model="page.add"
        width="600"
        persistent
        :fullscreen="device.mobile"
      >
        <v-card>
          <v-card-title :class="`headline ` + theme.color + ` lighten-2 white--text`">
            Formulir Jadwal Ujian
          </v-card-title>

          <v-card-text>
            <v-row class="mt-2">
              <v-col cols="12">
                <v-select
                  label="Periode"
                  :color="theme.color"
                  v-model="record.master_periode_id"
                  :hide-details="device.desktop"
                  :items="periodes"
                  outlined
                  dense
                  @change="fetchSoals"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Prog. Keahlian"
                  :color="theme.color"
                  v-model="record.jurusans"
                  :hide-details="device.desktop"
                  :items="jurusans"
                  outlined
                  dense
                  multiple
                  @change="fetchSoals"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Kelas/Tingkat"
                  :color="theme.color"
                  v-model="record.master_tingkat_id"
                  :hide-details="device.desktop"
                  :items="tingkats"
                  outlined
                  dense
                  @change="fetchSoals"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Mata Pelajaran"
                  :color="theme.color"
                  v-model="record.master_mapel_id"
                  :hide-details="device.desktop"
                  :items="mapels"
                  outlined
                  dense
                  @change="fetchSoals"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Semester"
                  :color="theme.color"
                  v-model="record.master_semester_id"
                  :hide-details="device.desktop"
                  :items="semesters"
                  @change="fetchKegiatans"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Kegiatan"
                  :color="theme.color"
                  v-model="record.master_kegiatan_id"
                  :hide-details="device.desktop"
                  :items="kegiatans"
                  @change="fetchSoals"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Soal"
                  :color="theme.color"
                  v-model="record.master_soal_id"
                  :hide-details="device.desktop"
                  :items="soals"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  label="Guru"
                  :color="theme.color"
                  v-model="record.master_pegawai_id"
                  :hide-details="device.desktop"
                  :items="pegawais"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Keterangan Tambahan"
                  :color="theme.color"
                  :hide-details="device.desktop"
                  v-model="record.title"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="subtitle-1 grey--text">Parameter Penilaian Pilihan Ganda</div>
              </v-col>
              <v-col cols=12>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Jumlah Soal"
                      :color="theme.color"
                      :hide-details="device.desktop"
                      v-model="record.jumlah"
                      type="number"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Bobot"
                      :color="theme.color"
                      :hide-details="device.desktop"
                      v-model="record.bobot"
                      type="number"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                </v-row>
              </v-col>
              <v-col cols="12">
                <div class="subtitle-1 grey--text">Parameter Penilaian Pilihan Essai</div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Jumlah Soal Essai"
                  :color="theme.color"
                  :hide-details="device.desktop"
                  v-model="record.jumlah_soal_essai"
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <div class="subtitle-1 grey--text">Parameter Penilaian Akhir</div>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Bobot Persen PG "
                      :color="theme.color"
                      :hide-details="device.desktop"
                      v-model="record.bobot_pg_persen"
                      type="number"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Bobot Persen Essai "
                      :color="theme.color"
                      :hide-details="device.desktop"
                      v-model="record.bobot_essai_persen"
                      type="number"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Tanggal"
                  :color="theme.color"
                  :hide-details="device.desktop"
                  v-model="record.tanggal"
                  type="date"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Waktu"
                  :color="theme.color"
                  :hide-details="device.desktop"
                  v-model="record.waktu"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Jam Mul"
                  :color="theme.color"
                  :hide-details="device.desktop"
                  v-model="record.jam_mulai"
                  type="time"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Jam Sel"
                  :color="theme.color"
                  :hide-details="device.desktop"
                  v-model="record.jam_selesai"
                  type="time"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="TOKEN"
                  :color="theme.color"
                  :hide-details="device.desktop"
                  v-model="record.token"
                  disabled
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-switch
                  label="Aktif"
                  :color="theme.color"
                  v-model="record.status"
                  :hide-details="device.desktop"
                ></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  label="Random"
                  :color="theme.color"
                  v-model="record.as_random"
                  :hide-details="device.desktop"
                ></v-switch>
              </v-col>
              <v-col cols="4">
                <v-switch
                  label="Token"
                  :color="theme.color"
                  v-model="record.is_token"
                  :hide-details="device.desktop"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              @click="closeForm"
            >
              BATAL
            </v-btn>
            <v-btn
              :color="theme.color"
              text
              @click="postAddNewRecord"
              v-show="!page.edit"
            >TAMBAH</v-btn>
            <v-btn
              :color="theme.color"
              text
              @click="postUpdateRecord"
              v-show="page.edit"
            >UBAH</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "jadwal-ujian",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", width: 50, align: "center", sortable: false },
      {
        text: "MATA PELAJARAN",
        align: "start",
        sortable: false,
        value: "ujian",
      },

      { text: "KELAS", value: "jurusan" },
      { text: "JML.KELAS", value: "kelas", width: 150, align: "center" },
      { text: "WAKTU", value: "waktu" },
      { text: "TOKEN", value: "token" },
      {
        text: "AKSI",
        value: "id",
        width: 50,
        sortable: false,
        align: "center",
      },
    ],

    search: null,
    form: {
      addnew: false,
      edit: false,
    },
    periodes: [],
    jurusans: [],
    tingkats: [],
    semesters: [],
    mapels: [],
    kegiatans: [],
    pegawais: [],
    soals: [],
    token: null,
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/jadwal-ujian",
      pagination: false,
      key: "uuid",
      title: "JADWAL UJIAN",
      subtitle: "Berikut Daftar Seluruh Jadwal Ujian Yang Tersedia",
      breadcrumbs: [
        {
          text: "Jadwal Ujian",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });
    this.fetchPeriodes();
    this.fetchRecords();
    this.fetchJurusans();
    this.fetchTingkats();
    this.fetchMapels();
    this.fecthSemeters();
    this.fetchPegawais();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "postAddNew",
      "postUpdate",
      "postEdit",
      "fetchRecords",
      "postConfirmDelete",
    ]),
    openForm: function () {
      this.fecthToken().then(() => {
        this.setPage({
          add: true,
          edit: false,
        });
      });
    },
    closeForm: function () {
      this.setPage({
        add: false,
        edit: false,
      });
    },
    postAddNewRecord: function () {
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.closeForm();
      });
    },

    editRecord: function (val) {
      this.postEdit(val);
      this.setPage({
        add: true,
        edit: true,
      });
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.closeForm();
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    openRombel: function (val) {
      this.$router.push({ name: "rombel-ujian", params: { ujian_id: val } });
    },

    fetchPeriodes: async function () {
      let { data: data } = await this.http.post("api/master-periode-combo");
      this.periodes = data;
    },

    fetchJurusans: async function () {
      let { data: data } = await this.http.post("api/jurusan-combo");
      this.jurusans = data;
    },

    fetchTingkats: async function () {
      let { data: data } = await this.http.post("api/master-tingkat-combo");

      this.tingkats = data;
    },

    fetchMapels: async function () {
      let { data: data } = await this.http.post("api/master-mapel-combo");

      this.mapels = data;
    },

    fecthSemeters: async function () {
      let { data: data } = await this.http.post("api/master-semester-combo");

      this.semesters = data;
    },

    fetchKegiatans: async function () {
      let { data: data } = await this.http.post(
        "api/master-kegiatan-combo",
        this.record
      );

      this.kegiatans = data;
    },

    fetchSoals: async function () {
      let { data: data } = await this.http.post(
        "api/master-soal-combo",
        this.record
      );

      this.soals = data;
    },

    fetchPegawais: async function () {
      let { data: data } = await this.http.post("api/master-pegawai-combo");

      this.pegawais = data;
    },

    fecthToken: async function () {
      let { data } = await this.http.post("api/jadwal-ujian-token");

      this.token = data;
    },
  },
  watch: {
    "record.master_semester_id": function () {
      this.fetchKegiatans();
    },
    "record.master_kegiatan_id": function () {
      this.fetchSoals();
    },
    token: function () {
      this.record.token = this.token;
    },
  },
};
</script>
