<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col cols="12">
        <v-card class="animated fadeInUp rounded-0">
          <v-card-title :class="`flex flex-row-reverse  ` + theme.color + ` lighten-2`  ">
            <v-btn
              icon
              @click="fetchRecords"
            >
              <v-icon color="white">autorenew</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
            <v-col :cols="device.desktop ? 4:10">
              <v-text-field
                solo
                v-model="search"
                append-icon="mdi-magnify"
                label="Pencarian"
                hide-details
                :color="theme"
              ></v-text-field>
            </v-col>
          </v-card-title>
          <v-data-table
            v-show="device.desktop"
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme"
              height="1"
              indeterminate
            ></v-progress-linear>

            <template v-slot:item.id="{ value }">
              <v-tooltip
                color="green"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="green"
                      @click=""
                    >restart_alt<span class="material-icons"> </span></v-icon>
                  </v-btn>
                </template>
                <span> Masih Di Kembangkan</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-list
            subheader
            v-show="device.mobile"
          >

            <v-list-item-group active-class="">
              <v-list-item
                v-for="item in filterItems"
                :key="item.id"
                style="border: ;1px solid"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.nisn }} </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.jenis_kelamin }} </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.ttl}} </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :color="theme.color"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-vertical-circle-outline
                        </v-icon>
                      </template>

                      <v-list>

                        <v-divider v-if="page.delete || page.edit"></v-divider>
                        <v-list-item
                          @click="editRecord(item.id)"
                          v-show="page.actions.edit"
                        >
                          <v-list-item-title>
                            <v-icon
                              class="mr-2"
                              color="orange"
                            >mdi-pencil-circle</v-icon>
                            Ubah Data
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="postDeleteRecord(item.id)"
                          v-show="page.actions.delete"
                        >
                          <v-list-item-title>
                            <v-icon
                              class="mr-2"
                              color="red"
                            >mdi-delete-circle</v-icon>
                            Hapus Data
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>

                </template>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num" },
      {
        text: "NIS",
        align: "start",
        sortable: false,
        value: "nis",
      },
      { text: "NISN", value: "nisn" },
      { text: "NAMA", value: "name" },
      { text: "(L/P)", value: "jenis_kelamin" },
      { text: "TTL", value: "ttl" },

      {
        text: "AKSI",
        value: "id",
        width: 85,
        align: "center",
        sortable: false,
      },
    ],

    search: null,
    form: {
      addnew: false,
      edit: false,
    },
    periodes: [],
    jurusans: [],
    tingkats: [],
    semesters: [],
    mapels: [],
    kegiatans: [],
    pegawais: [],
    soals: [],
    token: null,
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/wali-kelas-rombel",
      pagination: false,
      key: "uuid",
      title: "ROMBEL PER WALI KELAS",
      subtitle: "Berikut Daftar Seluruh Daftar Siswa Yang Tersedia",
      breadcrumbs: [
        {
          text: "Rombel Per Wali Kelas",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });

    this.fetchRecords();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "postAddNew",
      "postUpdate",
      "postEdit",
      "fetchRecords",
      "postConfirmDelete",
    ]),
    openAddNew: function () {
      this.fecthToken().then(() => {
        this.form.addnew = true;
      });
    },
    closeAddNew: function () {
      this.event.update = false;
      this.form.addnew = false;
    },

    postAddNewRecord: function () {
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.form.addnew = false;
      });
    },

    editRecord: function (val) {
      this.postEdit(val);
      this.form.addnew = true;
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.form.addnew = false;
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    openRombel: function (val) {
      this.$router.push({ name: "rombel-ujian", params: { ujian_id: val } });
    },

    fetchPeriodes: async function () {
      let { data: data } = await this.http.post("api/master-periode-combo");
      this.periodes = data;
    },

    fetchJurusans: async function () {
      let { data: data } = await this.http.post("api/jurusan-combo");
      this.jurusans = data;
    },

    fetchTingkats: async function () {
      let { data: data } = await this.http.post("api/master-tingkat-combo");

      this.tingkats = data;
    },

    fetchMapels: async function () {
      let { data: data } = await this.http.post("api/master-mapel-combo");

      this.mapels = data;
    },

    fecthSemeters: async function () {
      let { data: data } = await this.http.post("api/master-semester-combo");

      this.semesters = data;
    },

    fetchKegiatans: async function () {
      let { data: data } = await this.http.post(
        "api/master-kegiatan-combo",
        this.record
      );

      this.kegiatans = data;
    },

    fetchSoals: async function () {
      let { data: data } = await this.http.post(
        "api/master-soal-combo",
        this.record
      );

      this.soals = data;
    },

    fetchPegawais: async function () {
      let { data: data } = await this.http.post("api/master-pegawai-combo");

      this.pegawais = data;
    },

    fecthToken: async function () {
      let { data } = await this.http.post("api/jadwal-ujian-token");

      this.token = data;
    },

    postPrint: async function () {
      alert("cetak absensi");
    },
  },
  watch: {
    "record.master_semester_id": function () {
      this.fetchKegiatans();
    },
    "record.master_kegiatan_id": function () {
      this.fetchSoals();
    },
    token: function () {
      this.record.token = this.token;
    },
  },
};
</script>
