<template>
  <div class="home pa-6">
    <v-flex row class="pb-2">
      <v-icon>mdi-label</v-icon>
      <v-subheader> <h4>MANAJEMEN RAK</h4> </v-subheader>
    </v-flex>
    <v-flex class="pb-5 pl-6">
      <div class="grey--text mb-2">
        Daftar Seluruh Rak Yang Tersedia
      </div>
    </v-flex>

    <v-row>
      <v-col col="8">
        <v-card>
          <v-card-title class="flex flex-row-reverse">
            <v-btn icon @click="fetchRecords"
              ><v-icon color="green">autorenew</v-icon></v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              :color="theme"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.id="{ value }">
              <v-tooltip color="orange" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text small icon v-on="on">
                    <v-icon color="orange" @click="editRecord(value)"
                      >create</v-icon
                    >
                  </v-btn>
                </template>
                <span>Edit Data</span>
              </v-tooltip>
              <v-tooltip color="red" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text small icon v-on="on">
                    <v-icon color="red" @click="postDeleteRecord(value)"
                      >delete</v-icon
                    >
                  </v-btn>
                </template>

                <span>Hapus Data</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col col="4">
        <v-card>
          <v-card-title>
            Formulir Rak
          </v-card-title>
          <v-card-subtitle>
            Formulir untuk mendaftarkan/merubah rak
            <v-divider></v-divider>
          </v-card-subtitle>

          <v-card-text>
            <v-row :no-gutters="device.mobile">
              <v-col cols="12">
                <v-select
                  label="Blok"
                  :color="theme"
                  v-model="record.blok_id"
                  :items="bloks"
                  :hide-details="device.desktop"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Rak"
                  :color="theme"
                  hide-details="device.desktop"
                  autocomplete="off"
                  v-model="record.name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Keterangan"
                  :color="theme"
                  type="text"
                  hide-details="device.desktop"
                  autocomplete="off"
                  v-model="record.description"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="flex flex-row-reverse">
            <v-btn
              color="teal dark-3"
              @click="postAddNewRecord"
              v-show="!event.update"
              >TAMBAH</v-btn
            >
            <v-btn
              color="teal dark-3"
              @click="postUpdateRecord"
              v-show="event.update"
              >UBAH</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { domainURL } from "../../../.env";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num" },
      {
        text: "BLOK",
        align: "start",
        sortable: false,
        value: "blok.blok.name",
      },
      {
        text: "RAK",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "KETERANGAN", value: "description" },
      { text: "OPSI", value: "id" },
    ],

    search: null,

    bloks: [],
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/rak",
    });
    this.fetchRecords();
    this.postComboBlok();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
    ]),
    postAddNewRecord: function() {
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
      });
    },
    editRecord: function(val) {
      this.postEdit(val);
    },

    postUpdateRecord: function() {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
      });
    },

    postDeleteRecord: function(val) {
      this.postConfirmDelete(val);
    },

    postComboBlok: async function() {
      let { data: data } = await this.http.get("api/blok-combo");

      this.bloks = data;
    },
  },
};
</script>
