var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.device.desktop ? "home pa-6 grey lighten-4":"home pa-0 grey lighten-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"animated animate__fadeInUp rounded-0"},[_c('v-card-title',{class:"flex flex-row-reverse "+  _vm.theme.color + " lighten-2"},[_c('v-tooltip',{attrs:{"color":_vm.theme.color,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.page.actions.add),expression:"page.actions.add"}],attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":_vm.theme.mode == 'dark' ? "white" : "black"},on:{"click":_vm.openForm}},[_vm._v("add_circle")])],1)]}}])},[_c('span',[_vm._v("Tambah Data")])]),_c('v-tooltip',{attrs:{"color":_vm.theme.color,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.page.actions.refresh),expression:"page.actions.refresh"}],attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":_vm.theme.mode == 'dark' ? "white" : "black"},on:{"click":_vm.fetchRecords}},[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Data")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","solo":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.desktop),expression:"device.desktop"}],staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme.color,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value.color}},[_vm._v(_vm._s(value.text))])]}},{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.theme.color}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical-circle-outline")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openDocument(value)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.theme.color}},[_vm._v("mdi-folder")]),_vm._v("Dokumen Pegawai ")],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.editRecord(value)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"orange"}},[_vm._v("mdi-pencil-circle")]),_vm._v("Ubah ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.postDeleteRecord(value)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v("mdi-delete-circle")]),_vm._v("Hapus ")],1)],1)],1)],1)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme.color,"height":"1","indeterminate":""},slot:"progress"})],1),_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.mobile),expression:"device.mobile"}],attrs:{"subheader":""}},[_c('v-list-item-group',{attrs:{"active-class":""}},_vm._l((_vm.filterItems),function(item){return _c('v-list-item',{key:item.id,staticStyle:{"border":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.num +". "+ item.nama))]),_c('v-list-item-subtitle',[_vm._v("NIP. "+_vm._s(item.nip)+" ")]),_c('v-list-item-subtitle',[_vm._v("NUPTK. "+_vm._s(item.nuptk)+" ")]),_c('v-list-item-subtitle',[_c('v-chip',{attrs:{"small":"","color":item.status.color}},[_vm._v(_vm._s(item.status.text))])],1)],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.theme.color}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical-circle-outline ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.page.actions.edit),expression:"page.actions.edit"}],on:{"click":function($event){return _vm.editRecord(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.theme.color}},[_vm._v("mdi-folder")]),_vm._v(" Dokumen Pegawai ")],1)],1),(_vm.page.delete || _vm.page.edit)?_c('v-divider'):_vm._e(),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.page.actions.edit),expression:"page.actions.edit"}],on:{"click":function($event){return _vm.editRecord(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"orange"}},[_vm._v("mdi-pencil-circle")]),_vm._v(" Ubah Data ")],1)],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.page.actions.delete),expression:"page.actions.delete"}],on:{"click":function($event){return _vm.postDeleteRecord(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v("mdi-delete-circle")]),_vm._v(" Hapus Data ")],1)],1)],1)],1)],1)]}}],null,true)})}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }