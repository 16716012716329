<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title :class="`flex  `+  theme.color.color + ` lighten-2 white--text`">
            Formulir Tambah Pegawai
          </v-card-title>
          <v-card-subtitle :class="`flex  `+  theme.color.color + ` lighten-2 white--text`">
            Formulir untuk mendaftarkan/merubah pegawai
          </v-card-subtitle>
          <v-divider></v-divider>

          <v-card-text>
            <v-row :no-gutters="device.mobile">
              <v-col cols="10">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Nama Panggilan"
                      :color="theme.color"
                      hide-details="device.desktop"
                      autocomplete="off"
                      v-model="record.username"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      label="User Level"
                      :color="theme.color"
                      v-model="record.authent"
                      :items="levels"
                      :hide-details="device.desktop"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Nama Lengkap"
                      :color="theme.color"
                      hide-details="device.desktop"
                      autocomplete="off"
                      v-model="record.nama"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="DUK"
                      :color="theme.color"
                      hide-details="device.desktop"
                      autocomplete="off"
                      v-model="record.duk"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="NIP"
                      :color="theme.color"
                      hide-details="device.desktop"
                      autocomplete="off"
                      v-model="record.nip"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="NUPTK"
                      :color="theme.color"
                      hide-details="device.desktop"
                      autocomplete="off"
                      v-model="record.nuptk"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Finger ID"
                      :color="theme.color"
                      hide-details="device.desktop"
                      autocomplete="off"
                      v-model="record.fid"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field
                      label="Tempat Lahir"
                      :color="theme.color"
                      :hide-details="device.desktop"
                      autocomplete="off"
                      v-model="record.tempat_lahir"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Tanggal Lahir"
                      :color="theme.color"
                      type="date"
                      :hide-details="device.desktop"
                      autocomplete="off"
                      v-model="record.tanggal_lahir"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      label="Jenis Kelamin"
                      :color="theme.color"
                      :hide-details="device.desktop"
                      v-model="record.jenis_kelamin"
                      :items="gens"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      label="Type Pegawai"
                      :color="theme.color"
                      :hide-details="device.desktop"
                      v-model="record.type"
                      :items="types"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      label="Jenis"
                      :color="theme.color"
                      :hide-details="device.desktop"
                      v-model="record.asn"
                      :items="asns"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      label="Jabatan"
                      :color="theme.color"
                      :hide-details="device.desktop"
                      v-model="record.master_jabatan_id"
                      :items="jabatans"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      label="Pendidikan"
                      :color="theme.color"
                      :hide-details="device.desktop"
                      v-model="record.master_pendidikan_id"
                      :items="pendidikans"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Nama Sekolah/Perguruan Tinggi"
                      :color="theme.color"
                      v-model="record.sekolah"
                      :hide-details="device.desktop"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Jurusan"
                      :color="theme.color"
                      v-model="record.jurusan"
                      :hide-details="device.desktop"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Alamat"
                      :color="theme.color"
                      v-model="record.alamat"
                      :hide-details="device.desktop"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Email"
                      :color="theme.color"
                      v-model="record.email"
                      :hide-details="device.desktop"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Telpon"
                      :color="theme.color"
                      v-model="record.telp"
                      :hide-details="device.desktop"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Kode POS"
                      :color="theme.color"
                      v-model="record.kode_pos"
                      :hide-details="device.desktop"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                      label="Aktif"
                      :color="theme.color"
                      v-model="record.status"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-img
                  :class="[theme.color + ' lighten-2 with-backdrop']"
                  :aspect-ratio="3 / 4"
                  :src="foto"
                  :lazy-src="foto"
                >
                  <div
                    class="d-flex flex-column align-center justify-center user-select-none"
                    style="height: 100%;"
                  >
                    <div style="position: absolute; right: 8px; bottom: 8px; height: 36px; width: 36px;">
                      <v-btn
                        icon
                        dark
                        @click="uploadFoto"
                      >
                        <v-icon>photo_camera</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-img>
                <center>Foto 3x4 Max: 2Mb</center>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="flex flex-row-reverse">
            <v-btn
              color="blue dark-3"
              outlined
              @click="postAddNewRecord"
            >SIMPAN</v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="red dark-3"
              @click="batal"
            >
              Batal
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "HomeCreate",
  data: () => ({
    num: 1,

    search: null,
    foto: "/",
    gens: [
      { text: "Laki-laki", value: "L" },
      { text: "Perempuan", value: "P" },
    ],
    levels: [
      { text: "Guru", value: "guru" },
      { text: "Tata Usaha", value: "tatausaha" },
    ],
    types: [
      { text: "Guru", value: 1 },
      { text: "Tata Usaha", value: 2 },
    ],
    asns: [
      { text: "NON ASN", value: 1 },
      { text: "ASN", value: 2 },
    ],
    jabatans: [],
    pendidikans: [],
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/master-pegawai",
      pagination: false,
      key: "uuid",
      title: "MASTER PEGAWAI",
      subtitle: "Formulir Data Pegawai Baru",
      breadcrumbs: [
        {
          text: "Master Pegawai",
          disabled: true,
          href: "",
        },
        {
          text: "Formulir Pegawai Baru",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });
    this.fetchJabatans();
    this.fetchPendidikans();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
    ]),
    postAddNewRecord: async function () {
      let {
        data: { status, message },
      } = await this.http.post("api/master-pegawai", this.record);

      if (!status) {
        this.snackbar.color = "red";
        this.snackbar.text = message;
        this.snackbar.state = true;
        return;
      }

      this.snackbar.color = "green";
      this.snackbar.text = message;
      this.snackbar.state = true;

      this.$router.push({ name: "master-pegawai" });
    },

    editRecord: function (val) {
      this.postEdit(val);
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.$router.push({ name: "buku" });
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    fetchJabatans: async function () {
      let { data: data } = await this.http.post("api/master-jabatan-combo");

      this.jabatans = data;
    },

    fetchPendidikans: async function () {
      let { data: data } = await this.http.post("api/master-pendidikan-combo");

      this.pendidikans = data;
    },

    uploadFoto: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "moui-logo",
        },
        callback: (response) => {
          setTimeout(() => {
            this.foto = response.path;
            this.record.photo = response.name;
          }, 1000);
        },
      });
    },

    batal: function () {
      this.$router.push({ name: "master-pegawai" });
    },
  },
};
</script>
