<template>
  <div class="home pa-6">
    <v-flex row class="pb-2">
      <v-icon>mdi-label</v-icon>
      <v-subheader> <h4>IDENTITAS SEKOLAH</h4> </v-subheader>
    </v-flex>
    <v-flex class="pb-5 pl-6">
      <div class="grey--text mb-2">
        Silahkan Isi Identitas Sekolah Anda
      </div>
    </v-flex>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Identitas Sekolah
          </v-card-title>
          <v-card-subtitle>
            Formulir Identitas Sekolah
          </v-card-subtitle>
          <v-card-text>
            <v-row no-gutter>
              <v-col cols="8">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Nama Sekolah"
                      :color="theme"
                      v-model="record.nama"
                      :hide-details="device.desktop"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="NPSN"
                      :color="theme"
                      v-model="record.npsn"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                accusamus assumenda corporis reiciendis, repellat nemo unde
                ipsam animi labore tempora voluptates harum incidunt ex ducimus,
                reprehenderit aperiam autem maxime nostrum.
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Sekolah",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num" },
      {
        text: "TINGKAT",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "KETERANGAN", value: "description" },
      { text: "OPSI", value: "id" },
    ],

    search: null,
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/master-tingkat",
    });
    this.fetchRecords();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
    ]),
    postAddNewRecord: function() {
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
      });
    },
    editRecord: function(val) {
      this.postEdit(val);
    },

    postUpdateRecord: function() {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
      });
    },

    postDeleteRecord: function(val) {
      this.postConfirmDelete(val);
    },
  },
};
</script>
