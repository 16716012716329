<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card class="animated fadeInUp rounded-0">
          <v-card-title :class="`flex flex-row-reverse ` + theme.color + ` lighten-2` ">
            <v-btn
              icon
              @click="$router.push({name:'wali-kelas-absensi-ujian-siswa'})"
            >
              <v-icon color="white">
                highlight_off<span class="material-icons"> </span></v-icon>
            </v-btn>

            <v-btn
              icon
              @click="postPrint"
            >
              <v-icon color="white">mdi-file-excel</v-icon>
            </v-btn>

            <v-btn
              icon
              @click="fetchRecords"
            >
              <v-icon color="white">autorenew</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
            <v-col :cols="device.desktop ?4:8">
              <v-text-field
                solo
                v-model="search"
                append-icon="mdi-magnify"
                label="Pencarian"
                single-line
                hide-details
                :color="theme.color"
              ></v-text-field>
            </v-col>
          </v-card-title>
          <v-data-table
            v-show="device.desktop"
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.status="{ value }">
              <v-chip
                small
                :color="
                  value == `SELESAI`
                    ? `green`
                    : value == `SEDANG MENGERJAKAN`
                    ? `yellow`
                    : `grey`
                "
              >{{ value }}</v-chip>
            </template>
            <template v-slot:item.id="{ value }">
              <v-tooltip
                color="green"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="green"
                      @click=""
                    >restart_alt<span class="material-icons"> </span></v-icon>
                  </v-btn>
                </template>
                <span>Restart Ke Status Ujian | Masih Di Kembangkan</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-list
            subheader
            v-show="device.mobile"
          >

            <v-list-item-group active-class="">
              <v-list-item
                v-for="item in filterItems"
                :key="item.id"
                style="border: ;1px solid"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>Mulai : {{ item.jam_mulai }} </v-list-item-subtitle>
                    <v-list-item-subtitle>Selesai :{{ item.jam_selesai }} </v-list-item-subtitle>
                    <v-list-item-subtitle>Nilai : {{ item.nilai }}</v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-chip
                        small
                        :color="item.status.color"
                      >{{ item.status.text }}</v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :color="theme.color"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-vertical-circle-outline
                        </v-icon>
                      </template>

                      <v-list>

                        <v-divider v-if="page.delete || page.edit"></v-divider>
                        <v-list-item
                          @click="editRecord(item.id)"
                          v-show="page.actions.edit"
                        >
                          <v-list-item-title>
                            <v-icon
                              class="mr-2"
                              color="orange"
                            >mdi-pencil-circle</v-icon>
                            Ubah Data
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="postDeleteRecord(item.id)"
                          v-show="page.actions.delete"
                        >
                          <v-list-item-title>
                            <v-icon
                              class="mr-2"
                              color="red"
                            >mdi-delete-circle</v-icon>
                            Hapus Data
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>

                </template>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", width: 50, align: "right", sortable: false },
      {
        text: "NIS",
        align: "start",
        sortable: false,
        value: "nis",
      },
      { text: "NAMA", value: "name" },
      { text: "MULAI", value: "jam_mulai" },
      { text: "SELESAI", value: "jam_selesai" },
      { text: "NILAI", value: "nilai" },
      { text: "STATUS", value: "status" },
      {
        text: "AKSI",
        value: "id",
        width: 85,
        align: "center",
        sortable: false,
      },
    ],

    search: null,
    form: {
      addnew: false,
      edit: false,
    },
    periodes: [],
    jurusans: [],
    tingkats: [],
    semesters: [],
    mapels: [],
    kegiatans: [],
    pegawais: [],
    soals: [],
    token: null,
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl:
        "api/wali-kelas-absensi-ujian-kelas-siswa/" +
        this.$route.params.ujian_id,
      title: "DAFTAR ABSENSI UJIAN SISWA",
      subtitle: "Berikut Daftar Seluruh Absensi Siswa Yang Tersedia",
      breadcrumbs: [
        {
          text: "Daftar Absensi Ujian Siswa",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });

    this.fetchRecords();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "postAddNew",
      "postUpdate",
      "postEdit",
      "fetchRecords",
      "postConfirmDelete",
    ]),
    openAddNew: function () {
      this.fecthToken().then(() => {
        this.form.addnew = true;
      });
    },
    closeAddNew: function () {
      this.event.update = false;
      this.form.addnew = false;
    },

    postAddNewRecord: function () {
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.form.addnew = false;
      });
    },

    editRecord: function (val) {
      this.postEdit(val);
      this.form.addnew = true;
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.form.addnew = false;
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    openRombel: function (val) {
      this.$router.push({ name: "rombel-ujian", params: { ujian_id: val } });
    },

    fetchPeriodes: async function () {
      let { data: data } = await this.http.post("api/master-periode-combo");
      this.periodes = data;
    },

    fetchJurusans: async function () {
      let { data: data } = await this.http.post("api/jurusan-combo");
      this.jurusans = data;
    },

    fetchTingkats: async function () {
      let { data: data } = await this.http.post("api/master-tingkat-combo");

      this.tingkats = data;
    },

    fetchMapels: async function () {
      let { data: data } = await this.http.post("api/master-mapel-combo");

      this.mapels = data;
    },

    fecthSemeters: async function () {
      let { data: data } = await this.http.post("api/master-semester-combo");

      this.semesters = data;
    },

    fetchKegiatans: async function () {
      let { data: data } = await this.http.post(
        "api/master-kegiatan-combo",
        this.record
      );

      this.kegiatans = data;
    },

    fetchSoals: async function () {
      let { data: data } = await this.http.post(
        "api/master-soal-combo",
        this.record
      );

      this.soals = data;
    },

    fetchPegawais: async function () {
      let { data: data } = await this.http.post("api/master-pegawai-combo");

      this.pegawais = data;
    },

    fecthToken: async function () {
      let { data } = await this.http.post("api/jadwal-ujian-token");

      this.token = data;
    },

    postPrint: async function () {
      try {
        let {
          data: { status, message, data, mapel, tanggal, kelas },
        } = await this.http.post("api/laporan-ujian-pdf", this.record);

        this.pdfRecords = data;

        var doc = new jsPDF();

        doc.setFontSize(13);
        doc.setFont("times", "bold");
        doc.text(65, 10, "SMK NEGERI 4 KAB. TANGERANG");
        doc.setFontSize(12);
        doc.setFont("times", "bold");
        doc.text(52, 15, "ABSENSI UJIAN SEKOLAH SATUAN PENDIDIKAN");

        doc.setFontSize(10);
        doc.setFont("times", "normal");
        doc.text(10, 30, "Mata Pelajaran");
        doc.text(35, 30, ": " + mapel);
        doc.text(10, 35, "Tanggal ");
        doc.text(35, 35, ": " + tanggal);
        doc.text(10, 40, "Kelas");
        doc.text(35, 40, ":" + kelas);

        doc.autoTable({
          startY: 45,
          startX: 0,
          theme: "grid",
          head: [
            [
              {
                content: "NO",
                styles: {
                  halign: "center",
                  cellWidth: "3",
                },
              },
              {
                content: "NIS",
                styles: {
                  halign: "center",
                },
              },
              {
                content: "NAMA",
                styles: {
                  halign: "center",
                },
              },
              {
                content: "L/P",
                styles: {
                  halign: "center",
                },
              },
              {
                content: "JAM MASUK",
                styles: {
                  halign: "center",
                },
              },
              {
                content: "JAM SELESAI",
                styles: {
                  halign: "center",
                },
              },
              {
                content: "NILAI",
                styles: {
                  halign: "center",
                },
              },
            ],
          ],
          body: this.pdfRecords,
          columnStyles: {
            0: {
              halign: "right",
              cellWidth: 10,
            },
            1: {
              halign: "center",
              cellWidth: 25,
            },
            3: {
              halign: "center",
              cellWidth: 10,
            },
            4: {
              halign: "center",
              cellWidth: 30,
            },
            5: {
              halign: "center",
              cellWidth: 30,
            },
            6: {
              halign: "center",
              cellWidth: 30,
            },
          },
        });

        doc.save("LAPORAN UJIAN");
      } catch (error) {
        alert(error);
      }
    },

    postExport: async function () {},
  },
  watch: {
    "record.master_semester_id": function () {
      this.fetchKegiatans();
    },
    "record.master_kegiatan_id": function () {
      this.fetchSoals();
    },
    token: function () {
      this.record.token = this.token;
    },
  },
};
</script>
