<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card class="animated fadeInUp rounded-0">
          <v-card-title :class="`flex flex-row-reverse ` + theme.color + ` lighten-2`">
            <v-btn
              icon
              @click="$router.go(-1)"
            >
              <v-icon color="white">highlight_off</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="openAddNew"
            >
              <v-icon color="white">add_circle</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="fetchRecords(this.record)"
            >
              <v-icon color="white">autorenew</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-col :cols="device.desktop ? `4` : `7`">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pencarian"
                single-line
                hide-details
                solo
                dense
              ></v-text-field>
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme"
              height="1"
              indeterminate
            ></v-progress-linear>

            <template v-slot:item.soal="{ value }">
              <p v-html="value"></p>
            </template>
            <template v-slot:item.pilihans="{ value }">
              <v-chip
                small
                :color="value.pil == value.a ? 'green' : grey"
              >{{
                value.a
              }}</v-chip>&nbsp;
              <v-chip
                small
                :color="value.pil == value.b ? 'green' : grey"
              >{{
                value.b
              }}</v-chip>&nbsp;
              <v-chip
                small
                :color="value.pil == value.c ? 'green' : grey"
              >{{
                value.c
              }}</v-chip>&nbsp;
              <v-chip
                small
                :color="value.pil == value.d ? 'green' : grey"
              >{{
                value.d
              }}</v-chip>&nbsp;
              <v-chip
                small
                :color="value.pil == value.e ? 'green' : grey"
              >{{
                value.e
              }}</v-chip>&nbsp;
            </template>
            <template v-slot:item.status="{ value }">
              <v-icon
                small
                :color="value == true ? 'green' : 'red'"
                v-show="true"
              >calendar_today</v-icon>
            </template>
            <template v-slot:item.id="{ value }">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="theme.color"
                    v-bind="attrs"
                    v-on="on"
                  >mdi-dots-vertical-circle-outline</v-icon>
                </template>

                <v-list>
                  <v-list-item @click="editRecord(value)">
                    <v-list-item-title>
                      <v-icon
                        class="mr-2"
                        color="orange"
                      >mdi-pencil-circle</v-icon>Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="postDeleteRecord(value)">
                    <v-list-item-title>
                      <v-icon
                        class="mr-2"
                        color="red"
                      >mdi-delete-circle</v-icon>Hapus
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", align: "right", sortable: false, width: 50 },

      { text: "SOAL", value: "soal" },
      { text: "JENIS", value: "type" },
      {
        text: "PILIHAN",
        value: "pilihans",
        align: "center",
        sortable: false,
        width: 250,
      },

      {
        text: "AKSI",
        value: "id",
        sortable: false,
        align: "center",
        width: 50,
      },
    ],

    search: null,
    form: {
      addnew: false,
      edit: false,
    },
    master_soal_id: null,
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/master-soal-header/" + this.$route.params.id,
      pagination: false,
      key: "uuid",
      title: "KELOLA BUTIR SOAL",
      subtitle: "Berikut Daftar Seluruh Butir Soal Yang Tersedia",
      breadcrumbs: [
        {
          text: "Kelola Butir Soal",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });
    this.master_soal_id = this.$route.params.id;
    this.record.master_soal_id = this.$route.params.id;

    this.fetchRecords(this.record);
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "postAddNew",
      "postUpdate",
      "postEdit",
      "fetchRecords",
      "postConfirmDelete",
    ]),
    openForm: function () {
      this.setPage({
        add: true,
        edit: false,
      });
    },
    closeForm: function () {
      this.setPage({
        add: false,
        edit: false,
      });
    },

    openAddNew: function () {
      this.$router.push({
        name: "create-soal",
        params: { master_soal_id: this.master_soal_id },
      });
    },
    closeAddNew: function () {
      this.event.update = false;
      this.form.addnew = false;
    },

    editRecord: function (val) {
      this.$router.push({
        name: "edit-soal",
        params: { master_soal_id: this.master_soal_id, id: val },
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },
  },
};
</script>
