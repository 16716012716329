<template>
  <div class="home pa-6">
    <v-flex
      row
      class="pb-2"
    >
      <v-icon
        :color="theme"
        @click="$router.go(-1)"
      >grading</v-icon>
      <v-subheader class="text-h6">
        <h4>DAFTAR NILAI UJIAN ESSAI SISWA ( {{  $route.params.name }} )</h4>
      </v-subheader>
    </v-flex>
    <v-flex class="pb-5 pl-7">
      <div class="grey--text mb-2">
        Daftar Seluruh nilai ujian siswa essai yang tersedia
      </div>
    </v-flex>

    <v-row>
      <v-col col="12">
        <v-card class="animated fadeInUp">
          <v-card-title class="flex flex-row-reverse purple">
            <v-tooltip
              :color="theme"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="fetchRecords"
                  >mdi-refresh-circle<span class="material-icons"> </span></v-icon>
                </v-btn>
              </template>
              <span>Refres Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="goBack()"
                  >mdi-close-circle<span class="material-icons"> </span></v-icon>
                </v-btn>
              </template>
              <span>Kembali</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-text-field
                solo
                v-model="search"
                append-icon="mdi-magnify"
                label="Pencarian"
                single-line
                hide-details
                dense
              ></v-text-field>
            </v-col>

          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.soal="{ value }">
              <span v-html="value"></span>
            </template>

            <template v-slot:item.file_jawaban="{ value }">
              <v-icon
                :color="value ? 'green' :'grey'"
                :disabled="value? false:true"
                @click="postDownload(value)"
              >mdi-cloud-download</v-icon>
            </template>
            <template v-slot:item.id="{ value }">
              <v-tooltip
                color="purple"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="purple"
                      @click="editRecord(value)"
                    >mdi-pencil-circle<span class="material-icons"> </span></v-icon>
                  </v-btn>
                </template>
                <span>Input Nilai</span>
              </v-tooltip>

            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog
        v-model="form.addnew"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="white--text purple">
            Formulir Penilaian Soal Essai
          </v-card-title>

          <v-card-text>
            <v-row class="pt-5">
              <v-col cols="12">
                <v-text-field
                  label="NIS"
                  :color="theme"
                  outlined
                  dense
                  hide-details
                  v-model="$route.params.nis"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="NAMA"
                  :color="theme"
                  outlined
                  dense
                  hide-details
                  v-model="$route.params.name"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Nilai"
                  :color="theme"
                  outlined
                  dense
                  hide-details
                  type="number"
                  v-model="record.nilai_essai"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              @click="form.addnew=false"
            > Batal </v-btn>
            <v-btn
              :color="theme"
              text
              @click="postUpdateRecord"
            >Simpan</v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "NO", value: "nomor", width: 75 },
      { text: "SOAL", value: "soal" },
      { text: "JAWABAN", value: "jawaban_essai_siswa" },
      { text: "FILE", value: "file_jawaban", sortable: false, width: 50 },
      {
        text: "NILAI",
        value: "nilai_essai",
        width: 100,
        sortable: false,
        align: "right",
      },
      {
        text: "AKSI",
        value: "id",
        align: "center",
        width: 100,
        sortable: false,
      },
    ],

    search: null,
    form: {
      addnew: false,
      edit: false,
    },
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "records",
      "record",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl:
        "api/guru/olah-nilai/" +
        this.$route.params.ujian_id +
        "/" +
        this.$route.params.master_siswa_id,
    });

    this.fetchRecords();
  },
  mounted() {},
  methods: {
    ...mapActions(["setPage", "fetchRecords", "postEdit", "postUpdate"]),
    editRecord: function (val) {
      this.postEdit(val).then(() => {
        this.form.addnew = true;
      });
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.form.addnew = false;
      });
    },

    postDownload(val) {
      window.open(val, "__blank");
    },
    goBack: async function () {
      try {
        let {
          data: { status, message },
        } = await this.http.post("api/guru/olah-nilai-count", {
          ujian_id: this.$route.params.ujian_id,
          master_siswa_id: this.$route.params.master_siswa_id,
        });

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = false;
          return;
        }

        this.$router.push({
          name: "guru-daftar-nilai-ujian-siswa",
          params: {
            ujian_id: this.$route.params.ujian_id,
            master_kelas_id: this.$route.params.master_kelas_id,
          },
        });
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan " + error;
        this.snackbar.state = true;
      }
    },
  },
  watch: {},
};
</script>
