<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col cols="6">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title :class="`flex flex-row-reverse ` + theme.color + ` lighten-2` ">
            <v-btn
              icon
              @click="fetchRecordAsal"
            >
              <v-icon color="white">autorenew</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              dense
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-col cols="6">
              <v-select
                label="Kelas Asal"
                solo
                hide-details
                dense
                v-model="recordasal.master_kelas_id_asal"
                :items="kelas"
                :color="theme.color"
              ></v-select>
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="recordsasal"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>

            <template v-slot:item.id="{ value }">
              <v-tooltip
                color="orange"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="orange"
                      @click="postMutasiKelas(value)"
                    >repeate</v-icon>
                  </v-btn>
                </template>
                <span>MUTASI</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title class="flex flex-row-reverse purple white--text">
            <v-btn
              icon
              @click="fetchRecordTujuan"
            >
              <v-icon color="white">autorenew</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              dense
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-col cols="6">
              <v-select
                label="Kelas Tujuan"
                solo
                hide-details
                dense
                v-model="recordtujuan.master_kelas_id_tujuan"
                :items="kelas"
                :color="theme.color"
              ></v-select>
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers2"
            :items="recordstujuan"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "mutasi-kelas",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num" },
      { text: "NIS", value: "nis" },
      { text: "NAMA", value: "name" },
      { text: "L/P", value: "jenis_kelamin" },
      { text: "OPSI", value: "id" },
    ],

    headers2: [
      { text: "#", value: "num" },
      { text: "NIS", value: "nis" },
      { text: "NAMA", value: "name" },
      { text: "L/P", value: "jenis_kelamin" },
    ],

    recordsasal: [],
    recordstujuan: [],
    recordasal: {},
    recordtujuan: {},
    kelas: [],

    search: null,
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/",
      pagination: false,
      title: "MUTASI KELAS SISWA",
      subtitle: "Berikut Daftar Seluruh Mutasi Kelas Siswa Yang Tersedia",
      breadcrumbs: [
        {
          text: "Mutasi Kelas Siswa",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });
    this.fetchKelas();
  },
  mounted() {},
  methods: {
    ...mapActions(["setPage"]),

    postMutasiKelas: async function (val) {
      try {
        this.recordtujuan.id = val;
        let {
          data: { status, message },
        } = await this.http.post("api/mutasi-kelas", this.recordtujuan);

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = "green";
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.fetchRecordAsal();
        this.fetchRecordTujuan();
        return;
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
      }
    },

    fetchKelas: async function () {
      try {
        let { data: data } = await this.http.post("api/master-kelas-combo");
        this.kelas = data;
      } catch (error) {}
    },

    fetchRecordAsal: async function () {
      let { data } = await this.http.post(
        "api/daftar-kelas-asal",
        this.recordasal
      );

      this.recordsasal = data;
    },

    fetchRecordTujuan: async function () {
      let { data } = await this.http.post(
        "api/daftar-kelas-tujuan",
        this.recordtujuan
      );

      this.recordstujuan = data;
    },
  },
};
</script>
