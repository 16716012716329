<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card class="animated fadeInUp rounded-0">
          <v-card-title :class="`flex flex-row-reverse ` + theme.color + ` lighten-2`">
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="fetchRecords"
                  >mdi-refresh-circle<span class="material-icons"> </span></v-icon>
                </v-btn>
              </template>
              <span>Refres Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="downloadXls"
                  >mdi-file-excel<span class="material-icons"> </span></v-icon>
                </v-btn>
              </template>
              <span>Export Daftar Nilai</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-text-field
                solo
                v-model="search"
                append-icon="mdi-magnify"
                label="Pencarian"
                single-line
                hide-details
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select
                label="PILIH KELAS"
                solo
                hide-details
                v-model="record.master_kelas_id"
                :items="kelas"
                dense
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                label="PILIH UJIAN"
                solo
                hide-details
                v-model="record.ujian_id"
                :items="ujians"
                dense
              ></v-select>
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.status="{ value }">
              <v-chip
                small
                :color="
                  value == `SELESAI`
                    ? `green`
                    : value == `SEDANG MENGERJAKAN`
                    ? `yellow`
                    : `grey`
                "
              >{{ value }}</v-chip>
            </template>
            <template v-slot:item.aksi="{ value }">
              <v-tooltip
                color="green"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="green"
                      @click="openOlahNilai(value)"
                    >mdi-timeline-check-outline<span class="material-icons"> </span></v-icon>
                  </v-btn>
                </template>
                <span>Olah Nilai Essai</span>
              </v-tooltip>
              <v-tooltip
                color="green"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="purple"
                      @click=""
                    >mdi-calculator<span class="material-icons"> </span></v-icon>
                  </v-btn>
                </template>
                <span>Hitung Ulang</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog
        v-model="form.addnew"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="headline blue lighten-2">
            Tambah Peserta Ujian
          </v-card-title>

          <v-card-text>
            <v-row
              :no-gutter="device.mobile"
              class="pt-5"
            >
              <v-col cols="12">
                <v-select
                  label="Nama Siswa"
                  :color="theme"
                  v-model="record.master_kelas_siswa_id"
                  solo
                  dense
                  hide-details
                  :items="siswas"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              @click="closeAddNew"
            > Batal </v-btn>
            <v-btn
              color="blue dark-3"
              text
              @click="postAddNewRecord"
              v-show="!event.update"
            >TAMBAH</v-btn>
            <v-btn
              color="blue dark-3"
              text
              @click="postUpdateRecord"
              v-show="event.update"
            >UBAH</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog.hapus"
        persistent
        max-width="300px"
      >
        <v-card>
          <v-card-title
            class="headline red white--text"
            light
          >
            <v-icon
              color="white"
              class="pr-3"
            >report_problem</v-icon>
            <span>Konfirmasi</span>
          </v-card-title>
          <v-card-text>
            <p class="pt-5">
              Apakah anda yakin akan menghapus data absen siswa tersebut...?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="theme"
              text
              @click="dialogClose"
            >Tidak</v-btn>
            <v-btn
              color="red"
              text
              @click="dialogDelete"
            >Ya, Saya Yakin</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog.hapusall"
        persistent
        max-width="300px"
      >
        <v-card>
          <v-card-title
            class="headline red white--text"
            light
          >
            <v-icon
              color="white"
              class="pr-3"
            >report_problem</v-icon>
            <span>Konfirmasi</span>
          </v-card-title>
          <v-card-text>
            <p class="pt-5">
              Apakah anda yakin akan menghapus data siswa tersebut, ini
              menyebakan akan terhapus seluruh data siswa tersebut...?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="theme"
              text
              @click="dialogCloseAll"
            >Tidak</v-btn>
            <v-btn
              color="red"
              text
              @click="dialogDeleteAll"
            >Ya, Saya Yakin</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", width: 75 },
      { text: "KELAS", value: "kelas", width: 100 },
      {
        text: "NIS",
        align: "center",
        value: "nis",
        width: 100,
      },
      { text: "NAMA", value: "name" },
      { text: "N. PG", value: "nilai" },
      { text: "N. ES", value: "nilai_essai" },
      { text: "N. AKHIR", value: "nilai_akhir" },
      { text: "STATUS", value: "status", align: "center" },
      {
        text: "AKSI",
        value: "aksi",
        align: "center",
        width: 100,
        sortable: false,
      },
    ],

    search: null,
    form: {
      addnew: false,
      edit: false,
    },
    periodes: [],
    jurusans: [],
    tingkats: [],
    semesters: [],
    mapels: [],
    kegiatans: [],
    pegawais: [],
    soals: [],
    token: null,
    records: [],
    record: {},
    ujians: [],
    kelas: [],
    dialog: {
      hapus: false,
      hapusall: false,
    },
    pdfRecords: [],
    siswas: [],
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "loading",
      "event",
      "snackbar",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl:
        "api/guru/daftar-nilai-ujian-siswa/" + this.$route.params.ujian_id,
      pagination: false,
      key: "uuid",
      title: "DAFTAR NILAI",
      subtitle: "Berikut Seluruh Daftar Nilai Yang Tersedia",
      breadcrumbs: [
        {
          text: "Daftar Nilai",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
      },
    });

    this.fetchUjian();
    this.fetchKelas();
    this.record.ujian_id = Number(this.$route.params.ujian_id);
    this.record.master_kelas_id = Number(this.$route.params.master_kelas_id);
    this.fetchRecords();
  },
  mounted() {},
  methods: {
    ...mapActions(["setPage", "fetchRecords", "setLoading"]),
    fetchUjian: async function () {
      let { data: data } = await this.http.post("api/get-ujian-aktif");

      this.ujians = data;
    },
    fetchDataSiswa: async function () {
      let { data: data } = await this.http.post(
        "api/kurikulum-daftar-siswa",
        this.record
      );

      this.siswas = data;
    },
    fetchKelas: async function () {
      let { data: data } = await this.http.post("api/get-kelas-ujian-combo");

      this.kelas = data;
    },
    fetchRecords: async function () {
      try {
        this.records = [];
        this.setLoading({ dialog: true, text: "Mohon menunggu..." });
        let { data } = await this.http.post(
          "api/guru/daftar-nilai-ujian-siswa",
          this.record
        );

        this.records = data;
      } catch (error) {
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },

    postAddNew: function () {
      this.fetchDataSiswa();
      this.form.addnew = true;
    },

    closeAddNew: function () {
      this.form.addnew = false;
    },

    postAddNewRecord: async function () {
      try {
        let {
          data: { status, message },
        } = await this.http.post("api/tambah-peserta-ujian", this.record);

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = "green";
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.fetchRecords();
        this.closeAddNew();
        return;
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
      }
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.form.addnew = false;
      });
    },

    postConfirmDialogDelete: function (val) {
      this.record.id = val;
      this.dialog.hapus = true;
    },

    postConfirmDialogDeleteAll: function (val) {
      this.record.id = val;
      this.dialog.hapusall = true;
    },

    dialogClose: function () {
      this.dialog.hapus = false;
    },
    dialogDelete: async function () {
      try {
        let {
          data: { status, message },
        } = await this.http.post("api/delete-absen", this.record);

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
        }

        this.snackbar.color = "green";
        this.snackbar.text = message;
        this.snackbar.state = true;

        this.fetchRecords();

        this.dialogClose();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
        this.dialogClose();
      }
    },
    dialogCloseAll: function () {
      this.dialog.hapusall = false;
    },
    dialogDeleteAll: async function () {
      try {
        let {
          data: { status, message },
        } = await this.http.post("api/delete-siswa", this.record);

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
        }

        this.snackbar.color = "green";
        this.snackbar.text = message;
        this.snackbar.state = true;

        this.fetchRecords();

        this.dialogClose();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
        this.dialogClose();
      }
    },
    printPdf: async function () {
      try {
        let {
          data: { status, message, data, mapel, tanggal, kelas },
        } = await this.http.post("api/laporan-ujian-pdf", this.record);

        this.pdfRecords = data;

        var doc = new jsPDF();

        doc.setFontSize(13);
        doc.setFont("times", "bold");
        doc.text(65, 10, "SMK NEGERI 4 KAB. TANGERANG");
        doc.setFontSize(12);
        doc.setFont("times", "bold");
        doc.text(52, 15, "ABSENSI UJIAN SEKOLAH SATUAN PENDIDIKAN");

        doc.setFontSize(10);
        doc.setFont("times", "normal");
        doc.text(10, 30, "Mata Pelajaran");
        doc.text(35, 30, ": " + mapel);
        doc.text(10, 35, "Tanggal ");
        doc.text(35, 35, ": " + tanggal);
        doc.text(10, 40, "Kelas");
        doc.text(35, 40, ":" + kelas);

        doc.autoTable({
          startY: 45,
          startX: 0,
          theme: "grid",
          head: [
            [
              {
                content: "NO",
                styles: {
                  halign: "center",
                  cellWidth: "3",
                },
              },
              {
                content: "NIS",
                styles: {
                  halign: "center",
                },
              },
              {
                content: "NAMA",
                styles: {
                  halign: "center",
                },
              },
              {
                content: "L/P",
                styles: {
                  halign: "center",
                },
              },
              {
                content: "JAM MASUK",
                styles: {
                  halign: "center",
                },
              },
              {
                content: "JAM SELESAI",
                styles: {
                  halign: "center",
                },
              },
              {
                content: "NILAI",
                styles: {
                  halign: "center",
                },
              },
            ],
          ],
          body: this.pdfRecords,
          columnStyles: {
            0: {
              halign: "right",
              cellWidth: 10,
            },
            1: {
              halign: "center",
              cellWidth: 25,
            },
            3: {
              halign: "center",
              cellWidth: 10,
            },
            4: {
              halign: "center",
              cellWidth: 30,
            },
            5: {
              halign: "center",
              cellWidth: 30,
            },
            6: {
              halign: "center",
              cellWidth: 30,
            },
          },
        });

        doc.save("LAPORAN UJIAN");
      } catch (error) {
        alert(error);
      }
    },
    downloadXls: async function () {
      try {
        let { data } = await this.http.post(
          "api/guru/daftar-nilai-ujian-siswa-export",
          this.record
        );

        var dataxls = XLSX.utils.json_to_sheet(data);

        // A workbook is the name given to an Excel file
        var wb = XLSX.utils.book_new(); // make Workbook of Excel

        // add Worksheet to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(wb, dataxls, "Daftar Nilai"); // sheetAName is name of Worksheet
        //XLSX.utils.book_append_sheet(wb, pokemonWS, "pokemons");

        // export Excel file
        XLSX.writeFile(wb, "daftar-nilai.xlsx"); // name of the file is 'book.xlsx'
      } catch (error) {
      } finally {
      }
    },
    openOlahNilai: function (value) {
      this.$router.push({
        name: "guru-olah-nilai",
        params: {
          ujian_id: value.ujian_id,
          master_kelas_id: this.record.master_kelas_id,
          master_siswa_id: value.master_siswa_id,
          nis: value.nis,
          name: value.name,
        },
      });
    },
  },
  watch: {},
};
</script>
