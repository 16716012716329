var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.device.desktop ? "home pa-6 grey lighten-4":"home pa-0 grey lighten-4"},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-card',{staticClass:"animated fadeInUp rounded-0"},[_c('v-card-title',{class:"flex flex-row-reverse " + _vm.theme.color + " lighten-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("highlight_off")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.openAddNew}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("add_circle")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.fetchRecords(this.record)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("autorenew")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":_vm.device.desktop ? "4" : "7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","solo":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.soal",fn:function(ref){
var value = ref.value;
return [_c('p',{domProps:{"innerHTML":_vm._s(value)}})]}},{key:"item.pilihans",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value.pil == value.a ? 'green' : _vm.grey}},[_vm._v(_vm._s(value.a))]),_vm._v(" "),_c('v-chip',{attrs:{"small":"","color":value.pil == value.b ? 'green' : _vm.grey}},[_vm._v(_vm._s(value.b))]),_vm._v(" "),_c('v-chip',{attrs:{"small":"","color":value.pil == value.c ? 'green' : _vm.grey}},[_vm._v(_vm._s(value.c))]),_vm._v(" "),_c('v-chip',{attrs:{"small":"","color":value.pil == value.d ? 'green' : _vm.grey}},[_vm._v(_vm._s(value.d))]),_vm._v(" "),_c('v-chip',{attrs:{"small":"","color":value.pil == value.e ? 'green' : _vm.grey}},[_vm._v(_vm._s(value.e))])]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"small":"","color":value == true ? 'green' : 'red'}},[_vm._v("calendar_today")])]}},{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.theme.color}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical-circle-outline")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editRecord(value)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"orange"}},[_vm._v("mdi-pencil-circle")]),_vm._v("Edit ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.postDeleteRecord(value)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v("mdi-delete-circle")]),_vm._v("Hapus ")],1)],1)],1)],1)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme,"height":"1","indeterminate":""},slot:"progress"})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }